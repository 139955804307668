import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {toggleCheckbox} from "./Forms";
import ImageViewer from "./ImageViewer";

/**
 * Display and select photos.
 * - projectId : Project id
 * - allPhotos : All the photos
 * - findingId : (optional) Finding id
 * - selectedIds : All the ids of the selected photos
 * - showCancel : (optional ; default true) Show the cancel button
 * - actionLabel : (optional ; default 'common.add') The label of the action button
 * - onAction(newSelectedIds) : The action to do when the action is done
 * - onDelete(photoId) : (optional) The action to do when the delete button besides a photo is clicked
 */
function PhotoSelector(props) {

    const {t} = useTranslation()

    const [checkedByIds, setCheckedByIds] = useState({})
    const [viewImage, setViewImage] = useState(null)

    const [forceRefresh, setForceRefresh] = useState({})
    const [allPhotosFilterOrphan, setAllPhotosFilterOrphan] = useState(true)
    const [allPhotosFilterNotOrphan, setAllPhotosFilterNotOrphan] = useState(true)

    const selectedIds = props.selectedIds ? props.selectedIds : []

    const filteredPhotos = []
    props.allPhotos && props.allPhotos.forEach(photo => {
            // Hide already selected
            if (selectedIds.includes(photo.id)) {
                return
            }

            // Hide by filters
            if (photo.orphan && !allPhotosFilterOrphan) {
                return
            }
            if (!photo.orphan && !allPhotosFilterNotOrphan) {
                return
            }

            filteredPhotos.push(photo)
        }
    )
    const showCancel = props.showCancel !== false
    const actionLabel = props.actionLabel ? props.actionLabel : 'common.add'

    function doAction() {
        const newSelectedIds = []
        Object.keys(checkedByIds).forEach(id => {
            if (checkedByIds[id]) {
                newSelectedIds.push(id)
            }
        })
        props.onAction(newSelectedIds)
    }

    function cancel() {
        props.onAction([])
    }

    function doDeletePhoto(photoId) {
        props.onDelete(photoId)
    }

    function changeSelectedPhoto(photoIdx) {
        if (photoIdx < 0) {
            photoIdx = filteredPhotos.length - 1
        }
        if (photoIdx >= filteredPhotos.length) {
            photoIdx = 0
        }

        const photo = filteredPhotos[photoIdx]
        setViewImage({
            photoId: photo.id,
            photoIdx: photoIdx,
            findingId: props.findingId,
            edit: props.findingId,
            previous: () => changeSelectedPhoto(photoIdx - 1),
            next: () => changeSelectedPhoto(photoIdx + 1),
            forceRefresh: () => setForceRefresh(prev => ({
                ...prev,
                [photo.id]: new Date().getTime()
            })),
        })
    }

    return (<>
        <ImageViewer
            projectId={props.projectId}
            photoId={viewImage?.photoId}
            findingId={viewImage?.findingId}
            edit={viewImage?.edit}
            selected={checkedByIds[viewImage?.photoId] ? checkedByIds[viewImage?.photoId] : false}
            onSelectToggle={() => toggleCheckbox(checkedByIds, setCheckedByIds, viewImage?.photoId)}
            onClose={changed => {
                setViewImage(null)
                // Refresh the image if changed
                if (changed && viewImage.forceRefresh) {
                    viewImage.forceRefresh()
                }
            }}
            onPrevious={viewImage?.previous}
            onNext={viewImage?.next}
        />

        <div className="scrollable-section">
            <div className="scrollable-section-inside">

                <div className="row">
                    <div className="col">
                        <div className="sticky-top">
                            {showCancel &&
                                <button className="btn btn-primary" type="button"
                                        onClick={() => cancel()}
                                >{t('common.cancel')}</button>
                            }
                            <button className="btn btn-success" type="button"
                                    onClick={() => doAction()}>{t(actionLabel)}</button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-check form-switch">
                            <input id="allPhotosFilterOrphan"
                                   className="form-check-input"
                                   type="checkbox"
                                   role="switch"
                                   checked={allPhotosFilterOrphan}
                                   onChange={() => setAllPhotosFilterOrphan(!allPhotosFilterOrphan)}
                            />
                            <label className="form-check-label"
                                   htmlFor="allPhotosFilterOrphan">{t('photo.filterOrphan')}</label>
                        </div>
                        <div className="form-check form-switch">
                            <input id="allPhotosFilterNotOrphan"
                                   className="form-check-input"
                                   type="checkbox"
                                   role="switch"
                                   checked={allPhotosFilterNotOrphan}
                                   onChange={() => setAllPhotosFilterNotOrphan(!allPhotosFilterNotOrphan)}
                            />
                            <label className="form-check-label"
                                   htmlFor="allPhotosFilterNotOrphan">{t('photo.filterNotOrphan')}</label>
                        </div>
                    </div>
                </div>

                <div className="row">

                    {filteredPhotos.map((photo, photoIdx) => (
                        <div key={photo.id}
                             className="card col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                            <div>
                                {props.onDelete &&
                                    <button
                                        width="20px"
                                        type="button" className="btn btn-danger btn-sm float-end"
                                        onClick={() => doDeletePhoto(photo.id)}
                                    >
                                        <i className="bi bi-trash"></i>
                                    </button>
                                }

                                {photo.orphan &&
                                    <span className="badge bg-info float-end">{t('photo.orphan')}</span>
                                }

                                <input type="checkbox"
                                       checked={checkedByIds[photo.id] ? checkedByIds[photo.id] : false}
                                       onChange={() => toggleCheckbox(checkedByIds, setCheckedByIds, photo.id)}
                                />

                                <img
                                    src={`/project/${props.projectId}/photos/${photo.id}?maxSide=1000&findingId=${props.findingId}&forceRefresh=${forceRefresh[photo.id]}`}
                                    className="card-img-top"
                                    onClick={() => changeSelectedPhoto(photoIdx)}
                                />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{photo.name}</h5>
                                <p className="card-text"></p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    </>)

}

export default PhotoSelector
