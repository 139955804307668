import React from "react";
import SearchInternalListLocal from "./SearchInternalListLocal";
import DelayedInput from "./DelayedInput";

/**
 * A component to edit a list of internal list with data locally.
 * - listByType : The list of internal list by type
 * - name : The name for the input
 * - type : The type of internal list (See ListType)
 * - dependOnId : (optional) The id of the dependency
 * - map : The current map
 * - newKeyProvider : (optional) A function to provide the next key
 * - startWithARow : (optional) If true, the list will start with a row
 * - onChangeMap : A callback function(newMap)
 */
function MapSearchInternalListLocal(props) {

    const map = props.map ? props.map : {}
    const entries = Object.entries(map)

    const ready = props.listByType && props.listByType[props.type]
    if (!ready) {
        return <></>
    }

    const newKeyProvider = props.newKeyProvider ? props.newKeyProvider : () => {
        return 'EMPTY_' + parseInt(Math.random() * 200)
    }

    if (props.startWithARow && entries.length === 0) {
        addValue()
    }

    function updateLabel(index, newLabel) {
        console.log(`[${props.name}] updateLabel`, newLabel)
        entries[index][0] = newLabel
        props.onChangeMap(toMap(entries))
    }

    function updateValue(index, newId) {
        const initialEntries = {...entries}
        console.log(`[${props.name}] updateValue`, index, newId)
        const newValue = newId ? newId : ''
        if (entries[index][1] !== newValue) {
            entries[index][1] = newValue
            props.onChangeMap(toMap(entries))
        }
    }

    function addValue() {
        console.log(`[${props.name}] addValue`)
        const nextKey = newKeyProvider()
        console.log(`[${props.name}] addValue`, nextKey)
        entries.push([nextKey, ''])
        props.onChangeMap(toMap(entries))
    }

    function removeValue(index) {
        console.log(`[${props.name}] removeValue`, index)
        entries.splice(index, 1)
        props.onChangeMap(toMap(entries))
    }

    function toMap(entries) {
        const map = {}
        entries.forEach(entry => map[entry[0]] = entry[1])
        return map
    }

    return (
        <>
            <table className="table">
                <tbody>
                {entries.map((entry, index) => {
                    return (
                        <tr key={entry[0]}>
                            <td>
                                <DelayedInput
                                    type="text" className="form-control"
                                    name={`${props.name}-label`}
                                    initialValue={entry[0].startsWith('EMPTY_') ? '' : entry[0]}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateLabel(index, newValue)}
                                />
                            </td>
                            <td>
                                <SearchInternalListLocal
                                    listByType={props.listByType}
                                    name={props.name}
                                    type={props.type}
                                    selectedId={entry[1]}
                                    onChangeSelectedItem={(newItem) => updateValue(index, newItem?.id)}
                                />
                            </td>
                            <td>
                                <button className="btn btn-danger float-end" type="button"
                                        onClick={() => removeValue(index)}>
                                    X
                                </button>

                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            <button className={'btn btn-success'} type="button" onClick={() => addValue()}>
                +
            </button>
        </>
    )
}

export default MapSearchInternalListLocal;
