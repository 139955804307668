import React, {useEffect, useRef, useState} from "react"
import './ImageViewer.css'
import {useTranslation} from "react-i18next"
import {autoRetry, failuresToToast} from "../service"
import {SketchPicker} from "react-color";
import GlobalErrorsAndWarnings from "./GlobalErrorsAndWarnings";

/**
 * Display image full screen.
 * - projectId : The project id
 * - photoId : The photo id
 * - findingId : (optional) the finding id
 * - selected : (optional) If the image is selected
 * - edit : (default: false) To show the annotations editor
 * - onSelectToggle : (optional) The action to do when the image is selected/de-selected
 * - onClose : The action to do when the image is closed function(changed)
 * - onPrevious : (optional) The action to do when the previous image is clicked
 * - onNext : (optional) The action to do when the next image is clicked
 */
function ImageViewer(props) {

    const {t} = useTranslation()

    const [error, setError] = useState(null)

    const [finding, setFinding] = useState(null)
    const [changed, setChanged] = useState(false)
    const [saving, setSaving] = useState(false)
    const [annotations, setAnnotations] = useState([])
    const [backgroundImage, setBackgroundImage] = useState(null)
    const [backgroundImageSrc, setBackgroundImageSrc] = useState(null)
    const [previousSavedAnnotations, setPreviousSavedAnnotations] = useState([])
    const [newAnnotation, setNewAnnotation] = useState(null)
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false)
    const [pickedColor, setPickedColor] = useState({
        r: 0,
        g: 255,
        b: 0,
    })
    const [coordinates, setCoordinates] = useState({
        top: 0,
        left: 0,
        bottom: 1,
        right: 1,
    })

    const canvasRef = useRef(null)

    // Disable body scrolling when the image is displayed
    useEffect(() => {
        if (props.photoId) {
            document.body.classList.add('no-scroll')
        } else {
            document.body.classList.remove('no-scroll')
        }
    }, [props.photoId])

    // Refresh the canvas when the window is resized
    useEffect(() => {
        window.addEventListener('resize', refreshCanvas)
        return () => window.removeEventListener('resize', refreshCanvas)
    })

    // Refresh the canvas when displayMobileMenu is changed
    useEffect(() => {
        refreshCanvas()
    }, [displayMobileMenu])

    // Follow mouse on canvas
    useEffect(() => {
        canvasRef.current && canvasRef.current.addEventListener('mousemove', mouseMove)
        canvasRef.current && canvasRef.current.addEventListener('touchstart', mouseMove)
        canvasRef.current && canvasRef.current.addEventListener('touchmove', mouseMove)
        canvasRef.current && canvasRef.current.addEventListener('touchend', mouseMove)
        return () => {
            canvasRef.current && canvasRef.current.removeEventListener('mousemove', mouseMove)
            canvasRef.current && canvasRef.current.removeEventListener('touchstart', mouseMove)
            canvasRef.current && canvasRef.current.removeEventListener('touchmove', mouseMove)
            canvasRef.current && canvasRef.current.removeEventListener('touchend', mouseMove)
        }
    })

    // Get the finding
    useEffect(() => {
        if (props.findingId) {
            failuresToToast(t('finding.context'), () => window.service.projectFindingGet(props.projectId, props.findingId), false).then(response => {
                const item = response.data.item
                setFinding(item)
                const annotations = item.photoAnnotationsByPhotoId[props.photoId] ? item.photoAnnotationsByPhotoId[props.photoId] : []
                setAnnotations([...annotations])
                setPreviousSavedAnnotations([...annotations])
            })
        }
    }, [props.findingId, props.photoId])

    // Update the canvas
    useEffect(() => {
        if (annotations) {
            refreshCanvas()
        }
    }, [props.projectId, props.photoId, props.findingId, backgroundImage, annotations, newAnnotation])

    // When changing the annotations, send finding update
    useEffect(() => {
        if (props.projectId === undefined || props.photoId === undefined) {
            return
        }
        if (annotations !== previousSavedAnnotations) {
            setChanged(true)
            setSaving(true)
            const annotationsToSave = annotations
            setError(null)
            autoRetry(t('common.edit'), () => window.service.projectFindingPhotoAnnotationUpdate(props.projectId, props.findingId, props.photoId, annotationsToSave), 5).then(response => {
                setSaving(false)
                if (response.data.success) {
                    setPreviousSavedAnnotations(annotationsToSave)
                } else {
                    setAnnotations(previousSavedAnnotations)
                    const error = response.data
                    setError(error)
                    setTimeout(() => setError(null), 5000)
                }
            })
        }
    }, [annotations, previousSavedAnnotations])

    if (!props.projectId || !props.photoId) {
        return <></>
    }

    const hasRectangleOrCircle = annotations.some(annotation => annotation.type === 'RECTANGLE' || annotation.type === 'CIRCLE')

    function mouseMove(e) {

        // Not editing
        if (!newAnnotation) {
            return
        }

        let nextNewAnnotation = {...newAnnotation}
        let pX, pY;
        const pressing = Boolean(e.buttons === 1 || e.type === 'touchstart' || e.type === 'touchmove')

        // Check if event is a touch event
        if (e.touches) {
            const rect = canvasRef.current.getBoundingClientRect();
            pX = e.touches[0] ? toPercentX(e.touches[0].clientX - rect.left) : nextNewAnnotation.x2
            pY = e.touches[0] ? toPercentY(e.touches[0].clientY - rect.top) : nextNewAnnotation.y2
        } else {
            pX = toPercentX(e.offsetX)
            pY = toPercentY(e.offsetY)
        }

        // First dot
        if (nextNewAnnotation.x1 === null) {
            if (pressing) {
                nextNewAnnotation.x1 = pX
                nextNewAnnotation.y1 = pY
            } else {
                // Nothing
                return
            }
        } else { // Second dot
            nextNewAnnotation.x2 = pX
            nextNewAnnotation.y2 = pY
            if (!pressing) { // Stopped clicking
                // Ignore text if coordinates are not good
                if (!(nextNewAnnotation.type === 'TEXT'
                    && (nextNewAnnotation.y2 < nextNewAnnotation.y1 || nextNewAnnotation.x2 < nextNewAnnotation.x1))) {
                    setAnnotations([...annotations, nextNewAnnotation])
                }
                nextNewAnnotation = null
            }
        }

        setNewAnnotation(nextNewAnnotation)
    }

    function drawStyle(ctx, annotation, c) {
        if (!c) {
            c = coordinates
        }

        ctx.lineWidth = toRealW(0.01, c)
        const color = annotation.color
        ctx.strokeStyle = '#' + ((1 << 24) + (color[0] << 16) + (color[1] << 8) + color[2]).toString(16).slice(1)

    }

    function drawArrow(ctx, annotation, c) {
        if (!c) {
            c = coordinates
        }
        drawStyle(ctx, annotation, c)

        let rX1 = toRealX(annotation.x1, c)
        let rY1 = toRealY(annotation.y1, c)
        let rX2 = toRealX(annotation.x2, c)
        let rY2 = toRealY(annotation.y2, c)
        let arrowLength = Math.floor(Math.sqrt(Math.pow(rX2 - rX1, 2) + Math.pow(rY2 - rY1, 2)))
        let angle = Math.atan2(rY2 - rY1, rX2 - rX1)

        ctx.save()

        ctx.translate(rX2, rY2)
        ctx.rotate(angle)

        ctx.beginPath()
        ctx.moveTo(0, 0)
        ctx.lineTo(-arrowLength, 0)
        ctx.stroke()

        ctx.rotate(Math.PI / 4)
        ctx.beginPath()
        ctx.moveTo(0, 0)
        ctx.lineTo(Math.floor(-arrowLength * 0.2), 0)
        ctx.stroke()

        ctx.rotate(-Math.PI / 2)
        ctx.beginPath()
        ctx.moveTo(0, 0)
        ctx.lineTo(Math.floor(-arrowLength * 0.2), 0)
        ctx.stroke()

        ctx.restore()

    }

    function drawCircle(ctx, annotation, c) {
        if (!c) {
            c = coordinates
        }
        drawStyle(ctx, annotation, c)

        const x1 = toRealX(annotation.x1, c)
        const y1 = toRealY(annotation.y1, c)
        const x2 = toRealX(annotation.x2, c)
        const y2 = toRealY(annotation.y2, c)
        const centerX = (x1 + x2) / 2
        const centerY = (y1 + y2) / 2
        const ellipseWidth = Math.abs(x2 - x1) / 2
        const ellipseHeight = Math.abs(y2 - y1) / 2
        ctx.beginPath()
        ctx.ellipse(centerX, centerY, ellipseWidth, ellipseHeight, 0, 0, 2 * Math.PI)
        ctx.stroke()

    }

    function drawRectangle(ctx, annotation, c) {
        if (!c) {
            c = coordinates
        }
        drawStyle(ctx, annotation, c)

        ctx.strokeRect(
            toRealX(annotation.x1, c), toRealY(annotation.y1, c),
            toRealW(annotation.x2 - annotation.x1, c), toRealH(annotation.y2 - annotation.y1, c)
        )

    }

    function drawText(ctx, annotation, c) {
        if (!c) {
            c = coordinates
        }
        drawStyle(ctx, annotation, c)

        // Don't show if coordinates are not good
        if (annotation.y2 < annotation.y1 || annotation.x2 < annotation.x1) {
            return
        }

        ctx.save()

        const fontSize = toRealH(annotation.y2 - annotation.y1, c)
        ctx.font = 'bold ' + fontSize + 'px Arial'
        ctx.fillStyle = ctx.strokeStyle
        ctx.fillText(annotation.text, toRealX(annotation.x1, c), toRealY(annotation.y2, c))

        ctx.restore()

    }

    function refreshCanvas() {
        if (!props.projectId || !props.photoId) {
            return
        }

        const withFindings = !props.edit && props.findingId
        const src = withFindings ? `/project/${props.projectId}/photos/${props.photoId}?maxSide=2000&findingId=${props.findingId}` : `/project/${props.projectId}/photos/${props.photoId}?maxSide=2000`

        if (backgroundImageSrc !== src) {
            // Download base image
            const img = new Image()
            img.addEventListener(
                "load",
                () => {
                    setBackgroundImage(img)
                },
                false,
            )
            img.src = src
            setBackgroundImageSrc(src)
        } else {
            if (backgroundImage) {
                // Draw the image

                // Get the canvas dimensions
                const canvas = canvasRef.current
                const rect = canvas.getBoundingClientRect()
                canvas.width = rect.width
                canvas.height = rect.height
                const canvasWidth = canvas.width
                const canvasHeight = canvas.height
                const ctx = canvas.getContext("2d")

                // Get the image size
                const imgWidth = backgroundImage.width
                const imgHeight = backgroundImage.height

                // Scale the image to fit the canvas
                const horizontalScale = canvasWidth / imgWidth
                const verticalScale = canvasHeight / imgHeight
                const scale = Math.min(horizontalScale, verticalScale)
                const scaledWidth = imgWidth * scale
                const scaledHeight = imgHeight * scale

                const rLeft = (canvasWidth - scaledWidth) / 2;
                const rTop = (canvasHeight - scaledHeight) / 2;
                const c = {
                    left: rLeft,
                    top: rTop,
                    right: rLeft + scaledWidth,
                    bottom: rTop + scaledHeight,
                }
                setCoordinates(c)

                // Draw the image
                ctx.clearRect(0, 0, canvasWidth, canvasHeight)
                // Center the image
                ctx.drawImage(backgroundImage, toRealX(0, c), toRealY(0, c), scaledWidth, scaledHeight)

                // Draw the annotations
                let annotationsToDraw = [...annotations]
                if (newAnnotation
                    && newAnnotation.x1 !== null && newAnnotation.y1 !== null
                    && newAnnotation.x2 !== null && newAnnotation.y2 !== null) {
                    annotationsToDraw.push(newAnnotation)
                }
                annotationsToDraw.forEach((annotation) => {
                        if (!annotation) {
                            return
                        }
                        switch (annotation.type) {
                            case 'ARROW':
                                drawArrow(ctx, annotation, c)
                                break
                            case 'CIRCLE':
                                drawCircle(ctx, annotation, c)
                                break
                            case 'RECTANGLE':
                                drawRectangle(ctx, annotation, c)
                                break
                            case 'TEXT':
                                drawText(ctx, annotation, c)
                                break
                        }
                    }
                )
            }
        }
    }

    function toPercentX(rX, c) {
        if (!c) {
            c = coordinates
        }
        const rWidth = c.right - c.left
        return (rX - c.left) / rWidth
    }

    function toPercentY(rY, c) {
        if (!c) {
            c = coordinates
        }
        const rHeight = c.bottom - c.top
        return (rY - c.top) / rHeight
    }

    function toRealX(pX, c) {
        if (!c) {
            c = coordinates
        }
        const rWidth = c.right - c.left
        return rWidth * pX + c.left
    }

    function toRealY(pY, c) {
        if (!c) {
            c = coordinates
        }
        const rHeight = c.bottom - c.top;
        return rHeight * pY + c.top
    }

    function toRealW(pW, c) {
        if (!c) {
            c = coordinates
        }
        const rWidth = c.right - c.left
        return rWidth * pW
    }

    function toRealH(pH, c) {
        if (!c) {
            c = coordinates
        }
        const rHeight = c.bottom - c.top;
        return rHeight * pH
    }

    function chooseColorPicker() {
        setShowColorPicker(true)
        setDisplayMobileMenu(false)
    }

    function chooseNewAnnotation(type) {

        let text = null
        if (type === 'TEXT') {
            text = prompt(t('imageViewer.textPrompt'))
            if (!text) {
                return
            }
        }

        const annotation = {
            type: type,
            x1: null,
            y1: null,
            x2: null,
            y2: null,
            color: [pickedColor.r, pickedColor.g, pickedColor.b],
            text: text,
        }
        setNewAnnotation(annotation)

        setDisplayMobileMenu(false)
    }

    function clearAnnotations() {
        setAnnotations([])
        setDisplayMobileMenu(false)
    }

    return <div className="fullscreen">
        <div className="north">
            <button type="button"
                    className="btn-close float-end"
                    onClick={() => props.onClose(changed)}
            ></button>
            {props.selected !== undefined &&
                <input type="checkbox"
                       className="select-check-box"
                       checked={props.selected}
                       onChange={() => props.onSelectToggle !== undefined && props.onSelectToggle()}
                />
            }
            {saving && <div className="spinner-border spinner-border-sm" role="status"></div>}
            <div className="errors">
                <GlobalErrorsAndWarnings formResult={error}/>
            </div>
        </div>
        <div className={`middle ${displayMobileMenu ? 'displayMobileMenu' : ''}`}>
            {showColorPicker && <SketchPicker
                className="colorPicker"
                color={pickedColor}
                width="min(90vw, 90vh)"
                onChangeComplete={(color) => {
                    setPickedColor(color.rgb)
                    setShowColorPicker(false)
                    if (newAnnotation) {
                        setNewAnnotation(prev => ({...prev, color: [color.rgb.r, color.rgb.g, color.rgb.b]}))
                    }
                }}
            />}

            {props.onPrevious && <button className="btn btn-primary" onClick={props.onPrevious}>
                <i className="bi bi-arrow-left"></i>
            </button>}

            <canvas ref={canvasRef}/>

            {props.onNext && <button className="btn btn-primary" onClick={props.onNext}>
                <i className="bi bi-arrow-right"></i>
            </button>}

        </div>
        <div className={`south ${displayMobileMenu ? 'displayMobileMenu' : ''}`}>
            {props.edit && <div className="row">
                <button className="btn btn-primary col-12 d-lg-none"
                        onClick={() => setDisplayMobileMenu(!displayMobileMenu)}
                >
                    <i className="bi bi-toggles"></i>
                </button>

                <button className={`btn ${displayMobileMenu ? '' : 'd-none'} col-12 d-lg-block col-lg`}
                        style={{
                            backgroundColor: '#' + ((1 << 24) + (pickedColor.r << 16) + (pickedColor.g << 8) + pickedColor.b).toString(16).slice(1),
                        }}
                        onClick={() => chooseColorPicker()}>{t('imageViewer.chooseColor')}
                </button>
                <button
                    className={`btn ${newAnnotation?.type === 'RECTANGLE' ? 'btn-primary' : 'btn-success'} ${displayMobileMenu ? '' : 'd-none'} col-12 d-lg-block col-lg`}
                    onClick={() => chooseNewAnnotation('RECTANGLE')}
                    disabled={hasRectangleOrCircle}
                >
                    {t('imageViewer.rectangle')}
                </button>
                <button
                    className={`btn ${newAnnotation?.type === 'CIRCLE' ? 'btn-primary' : 'btn-success'} ${displayMobileMenu ? '' : 'd-none'} col-12 d-lg-block col-lg`}
                    onClick={() => chooseNewAnnotation('CIRCLE')}
                    disabled={hasRectangleOrCircle}
                >
                    {t('imageViewer.circle')}
                </button>
                <button
                    className={`btn ${newAnnotation?.type === 'ARROW' ? 'btn-primary' : 'btn-success'} ${displayMobileMenu ? '' : 'd-none'} col-12 d-lg-block col-lg`}
                    onClick={() => chooseNewAnnotation('ARROW')}>{t('imageViewer.arrow')}
                </button>
                <button
                    className={`btn ${newAnnotation?.type === 'TEXT' ? 'btn-primary' : 'btn-success'} ${displayMobileMenu ? '' : 'd-none'} col-12 d-lg-block col-lg`}
                    onClick={() => chooseNewAnnotation('TEXT')}>{t('imageViewer.text')}
                </button>
                <button className={`btn btn-secondary ${displayMobileMenu ? '' : 'd-none'} col-12 d-lg-block col-lg`}
                        onClick={() => clearAnnotations()}>{t('imageViewer.clearAll')}
                </button>

            </div>}
        </div>
    </div>

}

export default ImageViewer
