import {useTranslation} from "react-i18next";

/**
 * Radio buttons for showing yes/no.
 * - name : The name for the input
 * - value : The current value
 * - onChangeValue : A callback function(newValue)
 */
function RadioButtonYesNo(props) {

    const {t, i18n} = useTranslation()

    const value = props.value ? props.value : false

    const items = [
        {value: true, label: t('common.yes')},
        {value: false, label: t('common.no')}
    ]

    return (
        <div>
            {items.map(item => (
                <div key={item.value} className="form-check">
                    <input className="form-check-input" type="radio"
                           id={item.value}
                           name={props.name}
                           checked={value === item.value}
                           onChange={() => props.onChangeValue(item.value)}
                    />
                    <label className="form-check-label" htmlFor={item.id}>
                        {item.label}
                    </label>
                </div>
            ))}
        </div>
    )

}

export default RadioButtonYesNo
