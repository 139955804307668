/**
 * Show any global errors and warnings.
 *
 * props.formResult: The form result
 */
function GlobalErrorsAndWarnings(props) {
    const formResult = props.formResult || {};
    let errors = []
    let warnings = []

    if (formResult.error) {
        const error = formResult.error
        errors.push(`${error.timestamp} - ${error.uniqueId} - ${error.message}`)
    }
    if (formResult.globalErrors) {
        for (const globalError of formResult.globalErrors) {
            errors.push(globalError)
        }
    }
    if (formResult.globalWarnings) {
        for (const globalWarning of formResult.globalWarnings) {
            warnings.push(globalWarning)
        }
    }

    if (errors.length === 0) {
        errors = null
    }
    if (warnings.length === 0) {
        warnings = null
    }

    return (<>
            {errors && <div className="alert alert-danger">
                {errors.map((error, index) => (
                    <div key={index}>{error}</div>
                ))}
            </div>
            }

            {warnings && <div className="alert alert-warning">
                {warnings.map((warn, index) => (
                    <div key={index}>{warn}</div>
                ))}
            </div>
            }
        </>
    )
}

export default GlobalErrorsAndWarnings;
