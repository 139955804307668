import React, {useEffect, useState} from "react";
import {autoRetry} from "../service";
import {useTranslation} from "react-i18next";
import PaginationControl from "../common/PaginationControl";
import {useLogin} from "../common/CustomHooks";
import {toIsoFullDate} from "../common/DateUtils";
import {booleanIcon} from "../common/BooleanUtils";
import DelayedInput from "../common/DelayedInput";

/**
 * The billing listing page.
 *
 * props.userEntitlements: The users entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function BillingBillListPage(props) {

    const {t} = useTranslation()

    // Initial details
    const [listResponse, setListResponse] = useState([])
    const [pageId, setPageId] = useState(1)

    useLogin(props.userEntitlements, props.needsLogin, '/billing/bill')

    useEffect(() => {
        if (props.userEntitlements && props.userEntitlements.isLoggedIn) {
            refreshPage(pageId)
        }
    }, [props.userEntitlements, pageId])

    if (!props.userEntitlements) {
        return <></>
    }

    function refreshPage(pageId) {
        setPageId(pageId)
        autoRetry(t('bill.context'), () => window.service.billFindAll(pageId), 5).then(response => {
            setListResponse(response.data)
        })
    }

    function updatePaid(bill, newValue) {
        autoRetry(t('bill.context'), () => window.service.billUpdatePaid(bill.billingId, newValue), 5).then(() => {
            refreshPage(pageId)
        })
    }

    return (
        <div className="row">
            <div className="col">

                <h2>{t('bill.title-plural')}</h2>

                <div className="clearfix"></div>

                <PaginationControl
                    className="float-end"
                    state={listResponse.pagination}
                    onPageChange={newPageId => setPageId(newPageId)}
                />

                <div className="clearfix"></div>

                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>{t('bill.billingId')}</th>
                            <th>{t('bill.period')}</th>
                            <th>{t('bill.business')}</th>
                            <th>{t('bill.billingDate')}</th>
                            <th>{t('bill.units')}</th>
                            <th>{t('bill.pricePerUnit')}</th>
                            <th>{t('bill.subtotal')}</th>
                            <th>{t('bill.tps')}</th>
                            <th>{t('bill.tvq')}</th>
                            <th>{t('bill.total')}</th>
                            <th>{t('bill.emailNotified')}</th>
                            <th>{t('bill.errorSendingNotificationEmail')}</th>
                            <th>{t('bill.paid')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listResponse.items && listResponse.items.map(bill => (
                            <tr key={bill.billingId}>
                                <td>{bill.billingId}</td>
                                <td>{bill.period}</td>
                                <td>{bill.business.name}</td>
                                <td>{toIsoFullDate(bill.billingDate)}</td>
                                <td>{bill.units}</td>
                                <td>{(bill.pricePerUnitInCents / 100).toFixed(2)}</td>
                                <td>{((bill.pricePerUnitInCents * bill.units) / 100).toFixed(2)}</td>
                                <td>{(bill.tpsInCents / 100).toFixed(2)}</td>
                                <td>{(bill.tvqInCents / 100).toFixed(2)}</td>
                                <td>{(bill.totalInCents / 100).toFixed(2)}</td>
                                <td>{booleanIcon(bill.notificationEmailSentDate)}</td>
                                <td>{booleanIcon(!bill.errorSendingNotificationEmail)}</td>
                                <td>
                                    <DelayedInput
                                        name="paid"
                                        type="checkbox"
                                        initialValue={bill.paid}
                                        waitTimeInMs={200}
                                        onChange={newValue => updatePaid(bill, newValue)}
                                    />
                                    {bill.paid ? t('bill.paid') : t('bill.notPaid')}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <PaginationControl
                    className="float-end"
                    state={listResponse.pagination}
                    onPageChange={newPageId => setPageId(newPageId)}
                />

            </div>
        </div>
    )
}

export default BillingBillListPage
