import TypeAhead from "./TypeAhead";

/**
 * Typeahead for businesses.
 * - name : The name for the input
 * - onChangeSelectedItem : A callback function(newBusiness)
 */
function SearchBusiness(props) {

    return (
        <TypeAhead
            name={props.name}
            onChangeSelectedItem={(newBusiness) => props.onChangeSelectedItem(newBusiness)}
            makeSearch={(searchValue) => window.service.businessFindAll({search: searchValue})}
            initialSearch={true}
            selectFirstInitially={true}
            size="60"
            toId={(business) => business?.id}
            toDisplay={(business) => `${business.name} - ${business.address.full}`}
            toFieldSearch={(business) => business.name}
        />
    )

}

export default SearchBusiness
