import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLogin} from "../common/CustomHooks";
import GlobalErrorsAndWarnings from "../common/GlobalErrorsAndWarnings";
import {setFormValue, toggleCheckbox, updateFormValue} from "../common/Forms";
import FieldError from "../common/FieldError";
import {autoRetry, failuresToToast} from "../service";
import {toast} from "react-toastify";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import AddressAutocomplete from "../common/AddressAutocomplete";
import StringList from "../common/StringList";


/**
 * The business creation and edition page.
 *
 * props.googleMapsApiReady: True if the Google Maps API is ready
 * props.userEntitlements: The users entitlements
 * props.setBusinessSlug(slug): Set the business to get the entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function BusinessCreateEditPage(props) {

    const {slug} = useParams()

    const {t} = useTranslation()
    const navigate = useNavigate()

    const [formResult, setFormResult] = useState({})
    const [form, setForm] = useState({
        address: {
            full: ''
        },
        administrators: [],
        employees: [],
        inspectors: [],
    })
    const [formModuleAccess, setFormModuleAccess] = useState({
        register: true,
        project: false,
    })

    let redirectHash = '/business-edit/' + slug
    const isCreate = !slug
    if (isCreate) {
        redirectHash = '/business-new'
    }
    useLogin(props.userEntitlements, props.needsLogin, redirectHash)

    useEffect(() => {
        if (slug && props.userEntitlements && props.userEntitlements.isLoggedIn) {
            failuresToToast(t('business.context'), () => window.service.businessGet(slug), false).then(response => {
                console.log('response', response)
                setFormModuleAccess(response.data.item.moduleAccess)
                const item = response.data.item
                item.moduleAccess = undefined
                setForm(item)
            })
        }
    }, [slug, props.userEntitlements])

    useEffect(() => {
        props.setBusinessSlug(slug)
    }, [slug])

    const canUpdateBusinessDetails = isCreate || props.userEntitlements?.entitlements?.canUpdateBusinessDetails
    const canUpdateBusinessAdministrator = isCreate || props.userEntitlements?.entitlements?.canUpdateBusinessAdministrator
    const canUpdateBusinessEmployeesAndInspectors = isCreate || props.userEntitlements?.entitlements?.canUpdateBusinessEmployeesAndInspectors

    function createOrUpdate() {
        setFormResult(null)

        const fullForm = {...form}
        fullForm.moduleAccess = formModuleAccess

        if (slug) {
            autoRetry(t('common.edit'), () => window.service.businessEdit(slug, fullForm), 5).then(response => {
                setFormResult(response.data)
                if (response.data.success) {
                    toast.success(t('common.editSuccess'))

                    // Redirect
                    return navigate('/business/' + response.data.item.slug)
                }
            })
        } else {
            autoRetry(t('common.create'), () => window.service.businessCreate(fullForm), 5).then(response => {
                setFormResult(response.data)
                if (response.data.success) {
                    toast.success(t('common.createSuccess'))

                    // Redirect
                    return navigate('/business/' + response.data.item.slug)
                }
            })
        }
    }

    function updateAdministrators(newList) {
        const newForm = {...form}
        newForm.administrators = newList
        setForm(newForm)
    }

    function updateEmployees(newList) {
        const newForm = {...form}
        newForm.employees = newList
        setForm(newForm)
    }

    function updateInspectors(newList) {
        const newForm = {...form}
        newForm.inspectors = newList
        setForm(newForm)
    }

    return (
        <div className="row">
            <div className="col">

                <h2>{t('business.title')}</h2>
                <h3>{t('business.details')}</h3>

                <GlobalErrorsAndWarnings formResult={formResult}/>

                <div className="clearfix"></div>

                <table className="table">
                    <tbody>
                    <tr>
                        <th>{t('business.name')}</th>
                        <td>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="name"
                                   value={form.name}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                                   disabled={!canUpdateBusinessDetails}
                            />
                            <FieldError formResult={formResult} fieldName="name"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('business.neq')}</th>
                        <td>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="neq"
                                   value={form.neq}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                                   disabled={!canUpdateBusinessDetails}
                            />
                            <FieldError formResult={formResult} fieldName="neq"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('business.address')}</th>
                        <td>
                            {props.googleMapsApiReady && <AddressAutocomplete
                                address={form.address}
                                onChange={(e) => setFormValue(form, setForm, 'address', e)}
                                disabled={!canUpdateBusinessDetails}
                            />
                            }
                            <FieldError formResult={formResult} fieldName="address"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('business.phoneLocal')}</th>
                        <td>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="phoneLocal"
                                   value={form.phoneLocal}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                                   disabled={!canUpdateBusinessDetails}
                            />
                            <FieldError formResult={formResult} fieldName="phoneLocal"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('business.phoneNoFees')}</th>
                        <td>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="phoneNoFees"
                                   value={form.phoneNoFees}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                                   disabled={!canUpdateBusinessDetails}
                            />
                            <FieldError formResult={formResult} fieldName="phoneNoFees"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('business.email')}</th>
                        <td>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="email"
                                   value={form.email}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                                   disabled={!canUpdateBusinessDetails}
                            />
                            <FieldError formResult={formResult} fieldName="email"/>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2"></td>
                    </tr>
                    <tr>
                        <th>{t('business.tps')}</th>
                        <td>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="tps"
                                   value={form.tps}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                                   disabled={!canUpdateBusinessDetails}
                            />
                            <FieldError formResult={formResult} fieldName="tps"/>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('business.tvq')}</th>
                        <td>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="tvq"
                                   value={form.tvq}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                                   disabled={!canUpdateBusinessDetails}
                            />
                            <FieldError formResult={formResult} fieldName="tvq"/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h3>{t('business.moduleAccess.title')}</h3>

                <table className="table">
                    <tbody>
                    <tr>
                        <th>{t('business.moduleAccess.register')}</th>
                        <td><input type="checkbox"
                                   checked={formModuleAccess.register}
                                   onChange={() => toggleCheckbox(formModuleAccess, setFormModuleAccess, 'register')}
                                   disabled={!canUpdateBusinessDetails}
                        />
                        </td>
                    </tr>
                    <tr>
                        <th>{t('business.moduleAccess.project')}</th>
                        <td><input type="checkbox"
                                   checked={formModuleAccess.project}
                                   onChange={() => toggleCheckbox(formModuleAccess, setFormModuleAccess, 'project')}
                                   disabled={!canUpdateBusinessDetails}
                        />
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h3>{t('business.administratorsEdit')}</h3>

                <StringList
                    values={form.administrators}
                    disabled={!canUpdateBusinessAdministrator}
                    onChange={updateAdministrators}
                />
                <FieldError formResult={formResult} fieldName="administrators"/>

                <h3>{t('business.employeesEdit')}</h3>

                <StringList
                    values={form.employees}
                    disabled={!canUpdateBusinessEmployeesAndInspectors}
                    onChange={updateEmployees}
                />
                <FieldError formResult={formResult} fieldName="employees"/>

                <h3>{t('business.inspectorsEdit')}</h3>

                <StringList
                    values={form.inspectors}
                    disabled={!canUpdateBusinessEmployeesAndInspectors}
                    onChange={updateInspectors}
                />
                <FieldError formResult={formResult} fieldName="inspectors"/>

                <button className="btn btn-success float-end" type="button"
                        onClick={() => createOrUpdate()}>{t(slug ? 'common.edit' : 'common.create')}</button>
                <NavLink to={slug ? '/business/' + slug : '/business'}
                    className="btn btn-outline-primary float-end me-2">{t('common.cancel')}</NavLink>

            </div>
        </div>
    )
}

export default BusinessCreateEditPage
