import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

const google = window.google = window.google ? window.google : {}

/**
 * A field that uses Google Maps for autocomplete.
 *
 * props.disabled: If that field is disabled
 * props.address: The current address
 * props.address.full: The full search string or address
 *
 * props.onChange(address): The action to do when changing the address
 * address.full: The full search string or address  ||| result.formatted_address : "1060 Av. de Péribonka, Boisbriand, QC J7G 2Z7, Canada"
 * address.placeId: The place id
 */
function AddressAutocomplete(props) {

    const address = props.address || {
        full: '',
    }

    function onChange(full) {
        props.onChange({
            full: full,
            placeId: null,
        })
    }

    function onSelect(address, placeId, suggestion) {
        console.log('onSelect', address, placeId, suggestion)

        let map = new google.maps.Map(document.createElement('div'))
        new google.maps.places.PlacesService(map).getDetails({placeId: placeId}, function (place) {
            console.log('Address', place)

            // Process all the address components
            const addressComponents = {}
            place.address_components.forEach(component => {
                component.types.forEach(type => {
                    addressComponents[type] = component.long_name
                })
            })

            // Send the address
            props.onChange({
                full: place.formatted_address,
                placeId: placeId,
            })
        })
    }

    return (
        <PlacesAutocomplete
            value={address.full}
            onChange={onChange}
            onSelect={onSelect}
            searchOptions={{
                componentRestrictions: {country: "ca"},
            }}
        >
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                <div>
                    <input
                        {...getInputProps({
                            className: 'form-control location-search-input',
                        })}
                        disabled={props.disabled}
                    />
                    <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            const style = suggestion.active
                                ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                : {backgroundColor: '#ffffff', cursor: 'pointer'};
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                        key: suggestion.placeId,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}

export default AddressAutocomplete;
