export function cloneObject(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export function mergeObjects(obj1, obj2) {
    const merged = {}

    if (obj1) {
        for (let prop in obj1) {
            if (obj1[prop] !== null) {
                merged[prop] = obj1[prop]
            }
        }
    }

    if (obj2) {
        for (let prop in obj2) {
            if (obj2[prop] !== null) {
                merged[prop] = obj2[prop]
            }
        }
    }

    return merged
}
