import {toLangOnly} from "./LangUtils";
import {useTranslation} from "react-i18next";

/**
 * Checkboxes for internal list with data locally.
 * - listByType : The list of internal list by type
 * - name : The name for the input
 * - type : The type of internal list (See ListType)
 * - dependOnId : (optional) The id of the dependency
 * - selectedIds : The currently selected ids
 * - onChangeSelectedItems : A callback function(newItems)
 */
function CheckboxesInternalListLocal(props) {

    const {t, i18n} = useTranslation()

    const selectedIds = props.selectedIds ? props.selectedIds : []

    let items = []
    if (props.listByType && props.listByType[props.type]) {
        items = props.listByType[props.type]
    }
    if (props.dependOnId) {
        items = items.filter(internalList => internalList.dependOnId === props.dependOnId)
    }

    function toValue(internalList) {
        return internalList?.value[toLangOnly(i18n.language)];
    }

    function toggle(item) {
        let newIds = []
        if (selectedIds.includes(item.id)) {
            newIds = selectedIds.filter(id => id !== item.id)
        } else {
            newIds = [...selectedIds, item.id]
        }
        props.onChangeSelectedItems(items.filter(item => newIds.includes(item.id)))
    }

    return (
        <div>
            {items.map(item => (
                <div key={item.id} className="form-check">
                    <input className="form-check-input" type="checkbox"
                           id={item.id}
                           name={props.name}
                           checked={selectedIds.includes(item.id)}
                           onChange={() => toggle(item)}
                    />
                    <label className="form-check-label" htmlFor={item.id}>
                        {toValue(item)}
                    </label>
                </div>
            ))}
        </div>
    )

}

export default CheckboxesInternalListLocal
