import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import GlobalErrorsAndWarnings from "../common/GlobalErrorsAndWarnings";
import FieldError from "../common/FieldError";
import SearchInternalList from "../common/SearchInternalList";
import {setFormValue, updateFormValue} from "../common/Forms";
import {autoRetry} from "../service";
import {toast} from "react-toastify";
import {mergeObjects} from "../common/ObjectUtils";

/**
 * To create or edit a PresentPerson on a Project.
 *
 * props.project: The project
 * props.initialPresentPerson: (optional) the initial PresentPerson. When creating, will start with these values ; when editing, will show those values as a starting point
 * props.updated(project): The action to do when the PresentPerson is created or updated
 * props.cancel(): The action to do when the user clicks "cancel"
 */
function PresentPersonCreateEditComponent(props) {

    const {t} = useTranslation()

    const [formResult, setFormResult] = useState({})
    const [form, setForm] = useState(mergeObjects({
        id: null,

        firstName: '',
        lastName: '',

        titleId: '',
        otherTitle: '',

        duration: '',
        verifiedIdId: '',
    }, props.initialPresentPerson))

    const [titleFeatures, setTitleFeatures] = useState([])
    const [vefifiedIdFeatures, setVefifiedIdFeatures] = useState([])
    const [notVefifiedIdFeatures, setNotVefifiedIdFeatures] = useState([])

    function createOrEdit() {

        setFormResult(null)

        if (form.id) {
            autoRetry(t('common.edit'), () => window.service.projectPresentPersonUpdate(props.project.id, form.id, form), 5).then(response => {
                setFormResult(response.data)
                if (response.data.success) {
                    toast.success(t('common.editSuccess'))

                    props.updated && props.updated(response.data.item)
                }
            })
        } else {
            autoRetry(t('common.create'), () => window.service.projectPresentPersonAdd(props.project.id, form), 5).then(response => {
                setFormResult(response.data)
                if (response.data.success) {
                    toast.success(t('common.createSuccess'))

                    props.updated && props.updated(response.data.item)
                }
            })
        }
    }

    function changeSelectedTitle(newItem) {
        setFormValue(form, setForm, 'titleId', newItem?.id)
        setTitleFeatures(newItem?.features || [])
    }

    return (
        <div className="row edit-section">
            <div className="col">

                <h2>{t('project.presentPerson.title')}</h2>

                <GlobalErrorsAndWarnings formResult={formResult}/>

                <table className="table">
                    <tbody>
                    <tr>
                        <td>
                            <strong>{t('project.presentPerson.firstName')}</strong>
                            <br/>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="firstName"
                                   value={form.firstName}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                            />
                            <FieldError formResult={formResult} fieldName="firstName"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{t('project.presentPerson.lastName')}</strong>
                            <br/>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="lastName"
                                   value={form.lastName}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                            />
                            <FieldError formResult={formResult} fieldName="lastName"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{t('project.presentPerson.titleId')}</strong>
                            <br/>
                            <SearchInternalList
                                name="titleId"
                                type="PROJECT_PRESENT_PERSON_TITLE"
                                selectedId={form.titleId}
                                onChangeSelectedItem={changeSelectedTitle}
                            />
                            <FieldError formResult={formResult} fieldName="titleId"/>
                        </td>
                    </tr>
                    {titleFeatures.includes('other') &&
                        <tr>
                            <td>
                                <strong>{t('project.presentPerson.otherTitle')}</strong>
                                <br/>
                                <input type="text" className="form-control"
                                       autoComplete="off"
                                       name="otherTitle"
                                       value={form.otherTitle}
                                       onChange={(e) => updateFormValue(e, form, setForm)}
                                />
                                <FieldError formResult={formResult} fieldName="otherTitle"/>
                            </td>
                        </tr>
                    }
                    <tr>
                        <td>
                            <strong>{t('project.presentPerson.durationId')}</strong>
                            <br/>
                            <SearchInternalList
                                name="durationId"
                                type="PROJECT_PRESENT_PERSON_DURATION"
                                selectedId={form.durationId}
                                onChangeSelectedItem={newItem => setFormValue(form, setForm, 'durationId', newItem?.id)}
                            />
                            <FieldError formResult={formResult} fieldName="durationId"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{t('project.presentPerson.verifiedIdId')}</strong>
                            <br/>
                            <SearchInternalList
                                name="titleId"
                                type="PROJECT_PRESENT_PERSON_VERIFIED_ID"
                                selectedId={form.verifiedIdId}
                                onChangeSelectedItem={newItem => {
                                    setVefifiedIdFeatures(newItem?.features || [])
                                    setFormValue(form, setForm, 'verifiedIdId', newItem?.id)
                                }}
                            />
                            <FieldError formResult={formResult} fieldName="verifiedIdId"/>
                        </td>
                    </tr>
                    {vefifiedIdFeatures.includes('notVerified') &&
                        <tr>
                            <td>
                                <strong>{t('project.presentPerson.notVerifiedIdId')}</strong>
                                <br/>
                                <SearchInternalList
                                    name="titleId"
                                    type="PROJECT_PRESENT_PERSON_NOT_VERIFIED_ID"
                                    selectedId={form.notVerifiedIdId}
                                    onChangeSelectedItem={newItem => {
                                        setNotVefifiedIdFeatures(newItem?.features || [])
                                        setFormValue(form, setForm, 'notVerifiedIdId', newItem?.id)
                                    }}
                                />
                                <FieldError formResult={formResult} fieldName="notVerifiedIdId"/>
                            </td>
                        </tr>
                    }
                    {notVefifiedIdFeatures.includes('other') &&
                        <tr>
                            <td>
                                <strong>{t('project.presentPerson.notVerifiedIdOther')}</strong>
                                <br/>
                                <input type="text" className="form-control"
                                       autoComplete="off"
                                       name="notVerifiedIdOther"
                                       value={form.notVerifiedIdOther}
                                       onChange={(e) => updateFormValue(e, form, setForm)}
                                />
                                <FieldError formResult={formResult} fieldName="notVerifiedIdOther"/>
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>

                <button className="btn btn-success float-end" type="button"
                        onClick={() => createOrEdit()}>{form.id ? t('common.edit') : t('common.create')}</button>
                <button className="btn btn-outline-primary float-end" type="button"
                        onClick={() => props.cancel && props.cancel()}
                >{t('common.cancel')}</button>
            </div>
        </div>
    )
}

export default PresentPersonCreateEditComponent
