import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLogin} from "../common/CustomHooks";
import GlobalErrorsAndWarnings from "../common/GlobalErrorsAndWarnings";
import {setFormValue, toggleCheckbox, updateFormValue} from "../common/Forms";
import FieldError from "../common/FieldError";
import {NavLink} from "react-router-dom";
import AddressAutocomplete from "../common/AddressAutocomplete";
import {autoRetry, failuresToToast} from "../service";
import {toast} from "react-toastify";
import {toLangOnly} from "../common/LangUtils";
import SearchInspector from "../common/SearchInspector";

/**
 * The register submission.
 *
 * props.googleMapsApiReady: True if the Google Maps API is ready
 * props.userEntitlements: The users entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function RegisterAddPage(props) {

    const {t, i18n} = useTranslation()

    const [formResult, setFormResult] = useState({})
    const [form, setForm] = useState({
        address: {
            full: ''
        },
        typeId: '',
        date: '',
        inspectorEmail: '',
        confirmation: false,
    })
    const [types, setTypes] = useState([])

    useEffect(() => {
        failuresToToast(t('common.details'), () => window.service.internalListFindAll('REGISTER_INSPECTION_TYPE'), false).then(response => {
            console.log('response', response)
            const types = response.data.items

            // Add an empty type
            types.unshift({
                id: '',
                value: {
                    en: '',
                    fr: '',
                }
            })

            setTypes(types)
        })
    }, [])

    useLogin(props.userEntitlements, props.needsLogin, '/register-new')

    function submit() {
        setFormResult(null)
        autoRetry(t('common.create'), () => window.service.registerCreate(form), 5).then(response => {
            setFormResult(response.data)
            if (response.data.success) {
                toast.success(t('common.createSuccess'))
            }
        })
    }

    if (!props.userEntitlements) {
        return <></>
    }

    return (
        <div className="row">
            <div className="col">

                <h2>{t('register.title')}</h2>

                <GlobalErrorsAndWarnings formResult={formResult}/>

                <div className="clearfix"></div>
                <br/>

                <h3>{t('register.titleResponsibility')}</h3>
                <p>{t('register.generalTos')}</p>

                <h3>{t('register.inspectionDetails')}</h3>
                <table className="table">
                    <tbody>
                    <tr>
                        <th>{t('register.address')}</th>
                        <td>
                            {props.googleMapsApiReady && <AddressAutocomplete
                                address={form.address}
                                onChange={(e) => setFormValue(form, setForm, 'address', e)}
                            />
                            }
                            <FieldError formResult={formResult} fieldName="address"/>
                        </td>
                    </tr>

                    <tr>
                        <th>{t('register.type')}</th>
                        <td>
                            <select className="form-select"
                                    name="typeId"
                                    value={form.typeId}
                                    onChange={(e) => updateFormValue(e, form, setForm)}
                            >
                                {types.map((type) => (
                                    <option key={type.id}
                                            value={type.id}>{type.value[toLangOnly(i18n.language)]}</option>
                                ))}
                            </select>
                            <FieldError formResult={formResult} fieldName="typeId"/>
                        </td>
                    </tr>

                    <tr>
                        <th>{t('register.date')}</th>
                        <td>
                            <input type="date" className="form-control"
                                   autoComplete="off"
                                   name="date"
                                   value={form.date}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                            />
                            <FieldError formResult={formResult} fieldName="date"/>
                        </td>
                    </tr>

                    <tr>
                        <th>{t('register.inspector')}</th>
                        <td>
                            <SearchInspector
                                name="inspectorEmail"
                                selectFirstInitially={true}
                                onChangeSelectedItem={(newInspector) => setForm({
                                    ...form,
                                    inspectorEmail: newInspector ? newInspector.email : '',
                                })}
                            />
                            <FieldError formResult={formResult} fieldName="inspectorEmail"/>
                        </td>
                    </tr>

                    </tbody>
                </table>

                <h3>{t('register.confirmation')}</h3>
                <p>{t('register.confirmationP1')} {props.userEntitlements.userFullName}{t('register.confirmationP2')} {form.address.full ? form.address.full : 'xxxx'}{t('register.confirmationP3')} {form.date ? form.date : 'xxxx'}.</p>
                <p>{t('register.digitalSignature')}
                    <input type="checkbox" className="form-check-input ms-2"
                           checked={form.confirmation}
                           onChange={() => toggleCheckbox(form, setForm, 'confirmation')}
                    />
                    <FieldError formResult={formResult} fieldName="confirmation"/>
                </p>

                <button className="btn btn-success float-end" type="button"
                        onClick={() => submit()}>{t('common.submit')}</button>
                <NavLink to={'/register'}
                         className="btn btn-outline-primary float-end me-2">{t('common.cancel')}</NavLink>

            </div>
        </div>
    )
}

export default RegisterAddPage
