import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {autoRetry} from "../service";
import GoogleMapsSearch from "../common/GoogleMapsSearch";

/**
 * The register search page.
 *
 * props.googleMapsApiReady: True if the Google Maps API is ready
 */
function RegisterPage(props) {

    const {t} = useTranslation()

    // Initial details
    const [markers, setMarkers] = useState([])

    useEffect(() => {
        refreshPage()
    }, [])

    function refreshPage() {
        autoRetry(t('register.getMostRecentContext'), () => window.service.registerMostRecents(), 5).then(response => {
            setMarkers(response.data.items.map(item => {
                return {
                    lat: item.latitude,
                    lng: item.longitude,
                    label: `<h5>${item.full}</h5>
                        <p><strong>${t('register.mostRecentDate')}</strong> ${item.mostRecentDate}</p>
                        <p><strong>${t('register.count')}</strong> ${item.count}</p>
                    `,
                }
            }))
        })
    }

    return (
        <div className="row">
            <div className="col">

                <h2>{t('register.title')}</h2>

                <NavLink to="/register-new"
                         className="btn btn-success float-end">{t('register.addNewEntry')}</NavLink>

                <div className="clearfix"></div>

                <br/>

                {props.googleMapsApiReady && <GoogleMapsSearch markers={markers}/>}

            </div>
        </div>
    )
}

export default RegisterPage
