/**
 * Show any error for a field.
 *
 * props.formResult: The form result
 * props.fieldName: The field name
 */
function FieldError(props) {
    const formResult = props.formResult || {};
    const validationErrorsByField = formResult.validationErrorsByField || {}
    let errors = []
    if (validationErrorsByField) {
        errors = validationErrorsByField[props.fieldName] || []
    }
    if (errors.length === 0) {
        return null;
    }
    return (
        <div className="alert alert-danger">
            {errors.map((error, index) => (
                <div key={index}>{error}</div>
            ))}
        </div>
    )
}

export default FieldError;
