import React, {useEffect, useState} from "react";
import StringList from "./StringList";

/**
 * An input that will wait a bit before telling its value was updated.
 * - initialValue : The initial value
 * - waitTimeInMs : The time without changes to wait
 * - onChange : A callback function(newValue)
 */
function DelayedStringList(props) {

    const [value, setValue] = useState(props.initialValue ? props.initialValue : [])

    console.log(`[${props.name}] Initial Value: ${props.initialValue} ; Current Value: ${value}`)

    useEffect(() => {
            setValue(props.initialValue)
        },  // eslint-disable-next-line
        [props.initialValue])

    // Limit the service calls
    useEffect(() => {
            const timoutInstance = setTimeout(() => sendUpdate(), props.waitTimeInMs)
            return () => clearTimeout(timoutInstance)
        },  // eslint-disable-next-line
        [value])

    function sendUpdate() {
        if (props.initialValue !== value) {
            props.onChange && props.onChange(value)
        }
    }

    return (<>
            {props.initialValue === value && <i className="bi bi-check-circle bi-check-circle-list text-success"></i> ||
                <i className="bi bi-check-circle bi-check-circle-list text-info"></i>}
            <StringList
                values={value}
                onChange={setValue}
            />
        </>
    )

}

export default DelayedStringList
