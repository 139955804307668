import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLogin} from "../common/CustomHooks";
import GlobalErrorsAndWarnings from "../common/GlobalErrorsAndWarnings";
import FieldError from "../common/FieldError";
import {autoRetry} from "../service";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import SearchBusiness from "../common/SearchBusiness";

/**
 * The project creation page.
 *
 * props.userEntitlements: The users entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function ProjectCreatePage(props) {

    const {t} = useTranslation()
    const navigate = useNavigate()

    const [formResult, setFormResult] = useState({})
    const [form, setForm] = useState({
        businessId: '',
        businessSearch: '',
    })

    useLogin(props.userEntitlements, props.needsLogin, '/project-new')

    function create() {
        setFormResult(null)
        autoRetry(t('common.create'), () => window.service.projectCreate(form), 5).then(response => {
            setFormResult(response.data)
            if (response.data.success) {
                toast.success(t('common.createSuccess'))

                // Redirect
                return navigate('/project/' + response.data.item.id)
            }
        })
    }

    return (
        <div className="row">
            <div className="col">

                <h2>{t('project.title')}</h2>

                <GlobalErrorsAndWarnings formResult={formResult}/>

                <div className="clearfix"></div>

                <table className="table">
                    <tbody>
                    <tr>
                        <th>{t('business.title')}</th>
                        <td>
                            <SearchBusiness
                                name="businessId"
                                onChangeSelectedItem={(newBusiness) => setForm({
                                    ...form,
                                    businessId: newBusiness ? newBusiness.id : '',
                                })}
                            />
                            <FieldError formResult={formResult} fieldName="businessId"/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <button className="btn btn-success float-end" type="button"
                        onClick={() => create()}>{t('common.create')}</button>

            </div>
        </div>
    )
}

export default ProjectCreatePage
