import {useEffect} from "react";

/**
 * Configure Google Analytics.
 *
 * props.googleAnalyticsId: Google Analytics id
 */
function GoogleAnalytics(props) {

    useEffect(() => {
        let googleAnalyticsId = props.googleAnalyticsId
        if (googleAnalyticsId) {
            console.log('Configure Google Analytics with id: ' + googleAnalyticsId)
            window.dataLayer = window.dataLayer || []

            function gtag() {
                window.dataLayer.push(arguments)
            }

            gtag('js', new Date())
            gtag('config', googleAnalyticsId)
        }
    }, [props.googleAnalyticsId])

    return <></>
}

export default GoogleAnalytics
