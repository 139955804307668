import {useTranslation} from "react-i18next";
import React from "react";

/**
 * To show a badge with the amount of warnings.
 *
 * props.project: The project
 * props.prefix: (optional) The prefix of the id of the element
 */
function ProjectWarningsBadge(props) {

    const {t} = useTranslation()

    const warnings = props.project.warningsByField || {}
    let warningKeys
    // Get the keys starting with the prefix if set
    if (props.prefix) {
        warningKeys = Object.keys(warnings).filter(key => key.startsWith(props.prefix))
    } else {
        warningKeys = Object.keys(warnings)
    }

    // Count the amount of warnings in each key
    const hasWarnings = warningKeys.length > 0
    if (!hasWarnings) {
        return <></>
    }
    const amountOfWarnings = warningKeys.reduce((acc, key) => acc + warnings[key].length, 0)

    return <span className="badge text-bg-warning float-end">{amountOfWarnings} {t('common.warnings')}</span>

}

export default ProjectWarningsBadge
