import React, {useEffect, useState} from "react";
import {autoRetry} from "../service";
import {useTranslation} from "react-i18next";
import PaginationControl from "../common/PaginationControl";
import {useLogin} from "../common/CustomHooks";
import {actionWhenEnterKey} from "../common/Forms";
import {NavLink, useLocation} from "react-router-dom";

/**
 * The user listing page.
 *
 * props.userEntitlements: The users entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function UserListPage(props) {

    const {t} = useTranslation()

    function toBoolean(textValue, defaultValue) {
        if (textValue === 'true') {
            return true
        } else if (textValue === 'false') {
            return false
        } else {
            return defaultValue
        }
    }

    // Get the initial values or true by default
    const searchParams = new URLSearchParams(useLocation().search.substring(1))
    const initialIsAdmin = toBoolean(searchParams.get('isAdmin'), true)
    const initialIsInspector = toBoolean(searchParams.get('isInspector'), true)
    const initialIsBusinessAdministrator = toBoolean(searchParams.get('isBusinessAdministrator'), true)
    const initialIsBusinessEmployee = toBoolean(searchParams.get('isBusinessEmployee'), true)

    // Initial details
    const [listResponse, setListResponse] = useState([])
    const [pageId, setPageId] = useState(1)
    const [search, setSearch] = useState('')
    const [isAdmin, setIsAdmin] = useState(initialIsAdmin)
    const [isInspector, setIsInspector] = useState(initialIsInspector)
    const [isBusinessAdministrator, setIsBusinessAdministrator] = useState(initialIsBusinessAdministrator)
    const [isBusinessEmployee, setIsBusinessEmployee] = useState(initialIsBusinessEmployee)

    useLogin(props.userEntitlements, props.needsLogin, '/user')

    useEffect(() => {
        if (props.userEntitlements && props.userEntitlements.isLoggedIn) {
            refreshPage(pageId)
        }
    }, [props.userEntitlements, pageId, isAdmin, isInspector, isBusinessAdministrator, isBusinessEmployee])

    if (!props.userEntitlements) {
        return <></>
    }

    function refreshPage(pageId) {
        setPageId(pageId)
        autoRetry(t('user.context'), () => window.service.userFindAll(pageId, search, isAdmin, isInspector, isBusinessAdministrator, isBusinessEmployee), 5).then(response => {
            setListResponse(response.data)
        })
    }

    return (
        <div className="row">
            <div className="col">

                <h2>{t('user.title-plural')}</h2>

                <NavLink to="/login/create" className="btn btn-success float-end">{t('common.create')}</NavLink>

                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch"
                           id="isAdmin"
                           name="isAdmin"
                           checked={isAdmin}
                           onChange={e => setIsAdmin(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="isAdmin">{t('user.isAdmin')}</label>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch"
                           id="isInspector"
                           name="isInspector"
                           checked={isInspector}
                           onChange={e => setIsInspector(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="isInspector">{t('user.isInspector')}</label>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch"
                           id="isBusinessAdministrator"
                           name="isBusinessAdministrator"
                           checked={isBusinessAdministrator}
                           onChange={e => setIsBusinessAdministrator(e.target.checked)}
                    />
                    <label className="form-check-label"
                           htmlFor="isBusinessAdministrator">{t('user.isBusinessAdministrator')}</label>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch"
                           id="isBusinessEmployee"
                           name="isBusinessEmployee"
                           checked={isBusinessEmployee}
                           onChange={e => setIsBusinessEmployee(e.target.checked)}
                    />
                    <label className="form-check-label"
                           htmlFor="isBusinessEmployee">{t('user.isBusinessEmployee')}</label>
                </div>
                <br/>
                <div>
                    <input type="text" className="form-control"
                           autoComplete="off"
                           name="search"
                           value={search}
                           placeholder={t('common.search')}
                           onChange={(e) => setSearch(e.target.value)}
                           onKeyUp={e => actionWhenEnterKey(e, () => refreshPage(1))}
                    />
                </div>

                <br/>

                <PaginationControl
                    className="float-end"
                    state={listResponse.pagination}
                    onPageChange={newPageId => setPageId(newPageId)}
                />

                <div className="clearfix"></div>

                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>{t('user.email')}</th>
                            <th>{t('business.title-plural')}</th>
                            <th>{t('user.inspectorProfile.title')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listResponse.items && listResponse.items.map(user => (
                            <tr key={user.email}>
                                <td>
                                    {user.email}
                                </td>
                                <td>
                                    <ul>
                                        {user.businesses && user.businesses.map(business => (
                                            <li key={business.slug}>
                                                <NavLink to={'/business/' + business.slug}>{business.name}</NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                                <td>
                                    {user.inspector && <>

                                        <table className="table table-striped">
                                            <tbody>
                                            <tr>
                                                <th>{t('user.inspectorProfile.fullName')}</th>
                                                <td>{user.inspectorProfile.fullName}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('user.inspectorProfile.rbqLicenseNumber')}</th>
                                                <td>{user.inspectorProfile.rbqLicenseNumber}</td>
                                            </tr>
                                            <tr>
                                                <th>{t('user.inspectorProfile.insurance.title')}</th>
                                                <td>
                                                    <ul>
                                                        <li>
                                                            <strong>{t('user.inspectorProfile.insurance.name')}</strong>: {user.inspectorProfile.insurance.name}
                                                        </li>
                                                        <li>
                                                            <strong>{t('user.inspectorProfile.insurance.certificateNumber')}</strong>: {user.inspectorProfile.insurance.certificateNumber}
                                                        </li>

                                                        <li>
                                                            <strong>{t('user.inspectorProfile.insurance.professionalCoverage')}</strong>:
                                                            <ul>
                                                                <li>
                                                                    <strong>{t('user.inspectorProfile.insurance.policyNumber')}</strong>: {user.inspectorProfile.insurance.professionalCoveragePolicyNumber}
                                                                </li>
                                                                <li>
                                                                    <strong>{t('user.inspectorProfile.insurance.coverage')}</strong>:
                                                                    <ul>
                                                                        <li>
                                                                            <strong>{t('user.inspectorProfile.insurance.byDisaster')}</strong>: {user.inspectorProfile.insurance.professionalCoverageByDisaster}$
                                                                        </li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>
                                                                            <strong>{t('user.inspectorProfile.insurance.byPeriod')}</strong>: {user.inspectorProfile.insurance.professionalCoverageByPeriod}$
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>

                                                        <li>
                                                            <strong>{t('user.inspectorProfile.insurance.civilCoverage')}</strong>:
                                                            <ul>
                                                                <li>
                                                                    <strong>{t('user.inspectorProfile.insurance.policyNumber')}</strong>: {user.inspectorProfile.insurance.civilCoveragePolicyNumber}
                                                                </li>
                                                                <li>
                                                                    <strong>{t('user.inspectorProfile.insurance.coverage')}</strong>:
                                                                    <ul>
                                                                        <li>
                                                                            <strong>{t('user.inspectorProfile.insurance.byDisaster')}</strong>: {user.inspectorProfile.insurance.civilCoverageByDisaster}$
                                                                        </li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>
                                                                            <strong>{t('user.inspectorProfile.insurance.byPeriod')}</strong>: {user.inspectorProfile.insurance.civilCoverageByPeriod}$
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </>}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <PaginationControl
                    className="float-end"
                    state={listResponse.pagination}
                    onPageChange={newPageId => setPageId(newPageId)}
                />

            </div>
        </div>
    )
}

export default UserListPage
