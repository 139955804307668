import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-toastify/dist/ReactToastify.css';
import './app.css';
import {ToastContainer} from "react-toastify";
import React, {Suspense, useEffect, useState} from "react";
import {HashRouter as Router, Outlet, Route, Routes} from "react-router-dom";
import Header from './layout/Header.js';
import HomePage from "./home/HomePage";
import BusinessCreateEditPage from "./business/BusinessCreateEditPage";
import BusinessListPage from "./business/BusinessListPage";
import BusinessViewPage from "./business/BusinessViewPage";
import Footer from "./layout/Footer";
import GoogleAnalytics from "./common/GoogleAnalytics";
import LoginPage from "./login/LoginPage";
import UserCreateTokenPage from "./login/UserCreateTokenPage";
import UserChangePasswordPage from "./login/UserChangePasswordPage";
import {useTranslation} from "react-i18next";
import {autoRetry} from "./service";
import ProjectListPage from "./project/ProjectListPage";
import ProjectCreatePage from "./project/ProjectCreatePage";
import ProjectViewPage from "./project/ProjectViewPage";
import RegisterPage from "./register/RegisterPage";
import RegisterAddPage from "./register/RegisterAddPage";
import RequestListPage from "./request/RequestListPage";
import InspectorProfilePage from "./user/InspectorProfilePage";
import BillingProjectBillingListPage from "./billing/BillingProjectBillingListPage";
import BillingBillListPage from "./billing/BillingBillListPage";
import UserListPage from "./user/UserListPage";

function Page() {
    return <div className="Page"><Outlet/></div>
}

window.appConfig = {}

function App() {

    const {t} = useTranslation()

    const [userEntitlements, setUserEntitlements] = useState(null)
    const [appConfig, setAppConfig] = useState({})
    const [googleMapsApiReady, setGoogleMapsApiReady] = useState(false)
    const [loginRedirectionHash, setLoginRedirectionHash] = useState(null)
    const [businessSlug, setBusinessSlug] = useState(null)

    function refreshConfig() {
        autoRetry(t('common.config'), () => window.service.appDetailsConfigGet(), 10, true).then(response => {
            setAppConfig(response.data.item)
            window.appConfig = response.data.item

            // Add Google Maps API script
            const script = document.createElement("script");
            script.src = "https://maps.googleapis.com/maps/api/js?key=" + response.data.item.googleMapsPlacesApiKey + "&libraries=places&callback=initMap";
            script.async = true;
            window.initMap = function () {
                // JS API is loaded and available
                setGoogleMapsApiReady(true)
            };
            document.body.appendChild(script);

        })
    }

    function refreshEntitlements(businessSlug) {
        console.log('refreshEntitlements . businessSlug: ', businessSlug)
        autoRetry(t('user.getEntitlementsContext'), () => window.service.userGetEntitlements(businessSlug), 10, true).then(response => {
            setUserEntitlements(response.data.item)
        })
    }

    useEffect(() => {
        refreshConfig()
    }, [])

    useEffect(() => {
        refreshEntitlements(businessSlug)
    }, [businessSlug])

    function needsLogin(newHash) {
        setLoginRedirectionHash(newHash)
    }

    function onLogin() {
        setUserEntitlements(null)
        refreshEntitlements(businessSlug)
        setLoginRedirectionHash(null)
    }

    return (
        <Suspense>
            <Router>
                <div className="App">
                    <Header
                        userEntitlements={userEntitlements}
                        onLogout={() => refreshEntitlements()}
                    />
                    <div className="container-xxl">
                        <Routes>
                            <Route path="/" element={<Page/>}>
                                <Route path="" element={<HomePage
                                    userEntitlements={userEntitlements}
                                />}/>
                                <Route path="login">
                                    <Route path="" element={<LoginPage
                                        loginRedirectionHash={loginRedirectionHash}
                                        onLogin={() => onLogin()}
                                    />}/>
                                    <Route path="create" element={<UserCreateTokenPage action={'createUser'}/>}/>
                                    <Route path="forgot"
                                           element={<UserCreateTokenPage action={'forgotPasswordUser'}/>}/>
                                    <Route path="changePassword" element={<UserChangePasswordPage
                                        title={t('login.changePasswordUser.title')}/>}/>
                                </Route>
                                <Route path="business-new" element={<BusinessCreateEditPage
                                    googleMapsApiReady={googleMapsApiReady}
                                    userEntitlements={userEntitlements}
                                    needsLogin={newHash => needsLogin(newHash)}
                                    setBusinessSlug={setBusinessSlug}
                                />}/>
                                <Route path="business-edit/:slug" element={<BusinessCreateEditPage
                                    googleMapsApiReady={googleMapsApiReady}
                                    userEntitlements={userEntitlements}
                                    needsLogin={newHash => needsLogin(newHash)}
                                    setBusinessSlug={setBusinessSlug}
                                />}/>
                                <Route path="business/">
                                    <Route path=""
                                           element={<BusinessListPage
                                               userEntitlements={userEntitlements}
                                               needsLogin={newHash => needsLogin(newHash)}
                                           />
                                           }/>
                                    <Route path=":slug" element={<BusinessViewPage
                                        userEntitlements={userEntitlements}
                                        needsLogin={newHash => needsLogin(newHash)}
                                        setBusinessSlug={setBusinessSlug}
                                    />}/>
                                </Route>

                                <Route path="project-new" element={<ProjectCreatePage
                                    userEntitlements={userEntitlements}
                                    needsLogin={newHash => needsLogin(newHash)}
                                />}/>
                                <Route path="project/">
                                    <Route path=""
                                           element={<ProjectListPage
                                               userEntitlements={userEntitlements}
                                               needsLogin={newHash => needsLogin(newHash)}
                                           />
                                           }/>
                                    <Route path=":id" element={<ProjectViewPage
                                        googleMapsApiReady={googleMapsApiReady}
                                        userEntitlements={userEntitlements}
                                        needsLogin={newHash => needsLogin(newHash)}
                                    />}/>
                                </Route>

                                <Route path="register-new" element={<RegisterAddPage
                                    googleMapsApiReady={googleMapsApiReady}
                                    userEntitlements={userEntitlements}
                                    needsLogin={newHash => needsLogin(newHash)}
                                />}/>
                                <Route path="register/">
                                    <Route path="" element={<RegisterPage
                                        googleMapsApiReady={googleMapsApiReady}
                                    />}
                                    />
                                </Route>
                                <Route path="request/">
                                    <Route path="" element={<RequestListPage
                                        userEntitlements={userEntitlements}
                                        needsLogin={newHash => needsLogin(newHash)}
                                    />}
                                    />
                                </Route>
                                <Route path="user/">
                                    <Route path=""
                                           element={<UserListPage
                                               userEntitlements={userEntitlements}
                                               needsLogin={newHash => needsLogin(newHash)}
                                           />
                                           }/>
                                    <Route path="inspectorProfile" element={<InspectorProfilePage
                                        userEntitlements={userEntitlements}
                                        needsLogin={newHash => needsLogin(newHash)}
                                        refreshEntitlements={() => refreshEntitlements(null)}
                                    />}
                                    />
                                </Route>
                                <Route path="billing/">
                                    <Route path="projectBilling" element={<BillingProjectBillingListPage
                                        userEntitlements={userEntitlements}
                                        needsLogin={newHash => needsLogin(newHash)}
                                    />}
                                    />
                                    <Route path="bill" element={<BillingBillListPage
                                        userEntitlements={userEntitlements}
                                        needsLogin={newHash => needsLogin(newHash)}
                                    />}
                                    />
                                </Route>
                            </Route>
                        </Routes>
                        <Footer/>
                    </div>
                </div>
                <ToastContainer
                    position="top-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <GoogleAnalytics
                    googleAnalyticsId={appConfig.googleAnalyticsId}
                />
            </Router>
        </Suspense>
    );
}

export default App;
