import React from "react";
import {useTranslation} from "react-i18next";
import {toLangOnly} from "../common/LangUtils";
import {NavLink} from "react-router-dom";

/**
 * The home page.
 */
function HomePagePublic() {

    const {t, i18n} = useTranslation()

    return (<>
        <div className="row">
            <div className="col">
                <p>
                    <a href={`https://accesinspecteur.ca/${toLangOnly(i18n.language)}/form-only`} target="_blank">
                        {t('home.accesinspecteur')}
                    </a>
                </p>
                <p>
                    <NavLink to="/register">
                        {t('home.register')}
                    </NavLink>
                </p>
            </div>
        </div>
    </>)

}

export default HomePagePublic
