import React from "react";
import HomePagePublic from "./HomePagePublic";
import HomePageLoggedIn from "./HomePageLoggedIn";

/**
 * The home page.
 *
 * props.userEntitlements: The users entitlements
 */
function HomePage(props) {

    const userEntitlements = props.userEntitlements

    return (<>
        {!userEntitlements?.isLoggedIn && <HomePagePublic/>}
        {userEntitlements?.isLoggedIn && <HomePageLoggedIn
            userEntitlements={userEntitlements}
        />}
    </>)

}

export default HomePage
