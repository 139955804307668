import React, {useEffect, useState} from "react";
import {autoRetry} from "../service";
import {useTranslation} from "react-i18next";
import PaginationControl from "../common/PaginationControl";
import {useLogin} from "../common/CustomHooks";
import {toast} from "react-toastify";
import {toIsoFullDate} from "../common/DateUtils";

/**
 * The requests made and to approve listing page.
 *
 * props.userEntitlements: The users entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function RequestListPage(props) {

    const {t} = useTranslation()

    const [activeTab, setActiveTab] = useState(1)

    // Initial details
    const [mineListResponse, setMineListResponse] = useState([])
    const [minePageId, setMinePageId] = useState(1)
    const [approveListResponse, setApproveListResponse] = useState([])
    const [approvePageId, setApprovePageId] = useState(1)

    useLogin(props.userEntitlements, props.needsLogin, '/request')

    useEffect(() => {
        if (props.userEntitlements && props.userEntitlements.isLoggedIn) {
            refreshMinePage(minePageId)
            refreshApprovePage(approvePageId)
        }
    }, [props.userEntitlements, minePageId, approvePageId])

    if (!props.userEntitlements) {
        return <></>
    }

    function refreshMinePage(pageId) {
        setMinePageId(pageId)
        autoRetry(t('request.context'), () => window.service.requesFindAllMine(pageId), 5).then(response => {
            setMineListResponse(response.data)
        })
    }

    function refreshApprovePage(pageId) {
        setApprovePageId(pageId)
        autoRetry(t('request.context'), () => window.service.requesFindAllToApprove(pageId), 5).then(response => {
            setApproveListResponse(response.data)
        })
    }

    function deleteRequest(requestId) {
        autoRetry(t('request.context'), () => window.service.requestDelete(requestId), 5).then(response => {
            toast.success(t('common.delete'))
            refreshMinePage(minePageId)
        })
    }

    function approveRequest(requestId) {
        autoRetry(t('request.context'), () => window.service.requestAction(requestId, true), 5).then(response => {
            toast.success(t('common.approved'))
            refreshApprovePage(approvePageId)
        })
    }

    function rejectRequest(requestId) {
        autoRetry(t('request.context'), () => window.service.requestAction(requestId, false), 5).then(response => {
            toast.success(t('common.rejected'))
            refreshApprovePage(approvePageId)
        })
    }

    return (
        <div className="row">
            <div className="col">

                <h2>{t('request.title')}</h2>

                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <button
                            className={"nav-link " + (activeTab === 1 ? 'active' : '')}
                            onClick={() => setActiveTab(1)}>
                            {t('request.requested')}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={"nav-link " + (activeTab === 2 ? 'active' : '')}
                            onClick={() => setActiveTab(2)}>
                            {t('request.toApprove')}
                        </button>
                    </li>
                </ul>

                <br/>

                {activeTab === 1 && <>
                    <PaginationControl
                        className="float-end"
                        state={mineListResponse.pagination}
                        onPageChange={newPageId => setMinePageId(newPageId)}
                    />

                    <div className="clearfix"></div>

                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>{t('request.creationTime')}</th>
                                <th>{t('request.requestType')}</th>
                                <th>{t('request.previousFullName')}</th>
                                <th>{t('request.fullName')}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {mineListResponse.items && mineListResponse.items.map(request => (
                                <tr key={request.id}>
                                    <td>
                                        {toIsoFullDate(request.creationTime)}
                                    </td>
                                    <td>
                                        {t(`request.${request.requestType}.type`)}
                                    </td>
                                    <td>
                                        {request.requestContent.previousFullName}
                                    </td>
                                    <td>
                                        {request.requestContent.fullName}
                                    </td>
                                    <td>
                                        <button className="btn btn-danger"
                                                onClick={() => deleteRequest(request.id)}
                                        >{t('common.delete')}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <PaginationControl
                        className="float-end"
                        state={mineListResponse.pagination}
                        onPageChange={newPageId => setMinePageId(newPageId)}
                    />

                </>
                }

                {activeTab === 2 && <>
                    <PaginationControl
                        className="float-end"
                        state={mineListResponse.pagination}
                        onPageChange={newPageId => setMinePageId(newPageId)}
                    />

                    <div className="clearfix"></div>

                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>{t('request.creationTime')}</th>
                                <th>{t('request.creatorUserId')}</th>
                                <th>{t('request.requestType')}</th>
                                <th>{t('request.previousFullName')}</th>
                                <th>{t('request.fullName')}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {approveListResponse.items && approveListResponse.items.map(request => (
                                <tr key={request.id}>
                                    <td>
                                        {toIsoFullDate(request.creationTime)}
                                    </td>
                                    <td>
                                        {request.creatorUserId}
                                    </td>
                                    <td>
                                        {t(`request.${request.requestType}.type`)}
                                    </td>
                                    <td>
                                        {request.requestContent.previousFullName}
                                    </td>
                                    <td>
                                        {request.requestContent.fullName}
                                    </td>
                                    <td>
                                        <button className="btn btn-success"
                                                onClick={() => approveRequest(request.id)}
                                        >{t('common.approve')}
                                        </button>
                                        &nbsp;
                                        <button className="btn btn-danger"
                                                onClick={() => rejectRequest(request.id)}
                                        >{t('common.reject')}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <PaginationControl
                        className="float-end"
                        state={approveListResponse.pagination}
                        onPageChange={newPageId => setApprovePageId(newPageId)}
                    />

                </>
                }

            </div>
        </div>
    )
}

export default RequestListPage
