import {toLangOnly} from "./LangUtils";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

/**
 * Radio buttons for internal list with data locally.
 * - listByType : The list of internal list by type
 * - name : The name for the input
 * - type : The type of internal list (See ListType)
 * - addUnselected : (optional) True to add an empty item at the beginning
 * - dependOnId : (optional) The id of the dependency
 * - selectedId : The currently selected id
 * - onChangeSelectedItem : A callback function(newItem)
 */
function RadioButtonInternalListLocal(props) {

    const {t, i18n} = useTranslation()

    const selectedId = props.selectedId ? props.selectedId : null

    let items = []
    if (props.listByType && props.listByType[props.type]) {
        items = props.listByType[props.type]
    }
    if (props.dependOnId) {
        items = items.filter(internalList => internalList.dependOnId === props.dependOnId)
    }

    // When selectedId or dependOnId changes, check that the selectedId is still one in the list
    useEffect(() => {
        if (selectedId) {
            if (!items.find(item => item.id === selectedId)) {
                props.onChangeSelectedItem(null)
            }
        }
    }, [selectedId, props.dependOnId]);

    function toValue(internalList) {
        return internalList?.value[toLangOnly(i18n.language)];
    }

    return (
        <div>
            {items.map(item => (
                <div key={item.id} className="form-check">
                    <input className="form-check-input" type="radio"
                           id={item.id}
                           name={props.name}
                           checked={selectedId === item.id}
                           onChange={() => props.onChangeSelectedItem(item)}
                    />
                    <label className="form-check-label" htmlFor={item.id}>
                        {toValue(item)}
                    </label>
                </div>
            ))}
        </div>
    )

}

export default RadioButtonInternalListLocal
