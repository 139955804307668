import TypeAhead from "./TypeAhead";
import {toLangOnly} from "./LangUtils";
import {useTranslation} from "react-i18next";

/**
 * Typeahead for internal list with data locally.
 * - listByType : The list of internal list by type
 * - name : The name for the input
 * - type : The type of internal list (See ListType)
 * - dependOnId : (optional) The id of the dependency
 * - selectedId : (optional) If you want to select from outside
 * - onChangeSelectedItem : A callback function(newItem)
 */
function SearchInternalListLocal(props) {

    const {t, i18n} = useTranslation()

    const ready = props.listByType && props.listByType[props.type]
    if (!ready) {
        return <></>
    }

    function search(type, dependOnId, searchValue) {

        let list = []
        // Get the list
        if (props.listByType && props.listByType[type]) {
            list = props.listByType[type]
        }

        // Filters
        if (dependOnId) {
            list = list.filter(internalList => internalList.dependOnId === dependOnId)
        }
        if (searchValue) {
            list = list.filter(internalList => toValue(internalList)?.toLowerCase().includes(searchValue.toLowerCase()))
        }

        // Return a promise
        return new Promise((resolve, reject) => {
            resolve({
                data: {
                    items: list
                }
            })
        })
    }

    function toValue(internalList) {
        return internalList?.value[toLangOnly(i18n.language)];
    }

    return (
        <TypeAhead
            name={props.name}
            outsideChangedSelectedId={props.selectedId}
            onChangeSelectedItem={(newInternalList) => props.onChangeSelectedItem(newInternalList)}
            makeSearch={(searchValue) => search(props.type, props.dependOnId, searchValue)}
            initialSearch={true}
            selectFirstInitially={false}
            size="60"
            toId={(internalList) => internalList?.id}
            toDisplay={(internalList) => toValue(internalList)}
            toFieldSearch={(internalList) => toValue(internalList)}
            forceRefresh={[props.listByType, props.dependOnId]}
        />
    )

}

export default SearchInternalListLocal
