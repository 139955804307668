import React, {useEffect, useState} from "react";
import {autoRetry} from "../service";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PaginationControl from "../common/PaginationControl";
import {useLogin} from "../common/CustomHooks";
import {toast} from "react-toastify";

/**
 * The business listing page.
 *
 * props.userEntitlements: The users entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function BusinessListPage(props) {

    const {t} = useTranslation()

    // Initial details
    const [listResponse, setListResponse] = useState([])
    const [pageId, setPageId] = useState(1)

    useLogin(props.userEntitlements, props.needsLogin, '/business')

    useEffect(() => {
        if (props.userEntitlements && props.userEntitlements.isLoggedIn) {
            refreshPage(pageId)
        }
    }, [props.userEntitlements, pageId])

    if (!props.userEntitlements) {
        return <></>
    }

    function refreshPage(pageId) {
        setPageId(pageId)
        autoRetry(t('business.context'), () => window.service.businessFindAll({pageId}), 5).then(response => {
            setListResponse(response.data)
        })
    }

    function disable(slug) {
        autoRetry(t('business.context'), () => window.service.businessDisable(slug), 5).then(response => {
            toast.success(t('common.disabled'))
            refreshPage(pageId)
        })
    }

    function enable(slug) {
        autoRetry(t('business.context'), () => window.service.businessEnable(slug), 5).then(response => {
            toast.success(t('common.enabled'))
            refreshPage(pageId)
        })
    }

    return (
        <div className="row">
            <div className="col">

                <h2>{t('business.title-plural')}</h2>

                {props.userEntitlements.entitlements.canCreateBusiness &&
                    <NavLink to="/business-new" className="btn btn-success float-end">{t('common.create')}</NavLink>}

                <div className="clearfix"></div>

                <br/>

                <PaginationControl
                    className="float-end"
                    state={listResponse.pagination}
                    onPageChange={newPageId => setPageId(newPageId)}
                />

                <div className="clearfix"></div>

                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>{t('business.name')}</th>
                            <th>{t('business.email')}</th>
                            <th>{t('business.address')}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {listResponse.items && listResponse.items.map(business => (
                            <tr key={business.id} className={business.disabled ? 'disabled' : ''}>
                                <td>
                                    <NavLink to={`/business/${business.slug}`}>{business.name}</NavLink>
                                </td>
                                <td>
                                    {business.email}
                                </td>
                                <td>
                                    {business.address.full}
                                </td>
                                <td>
                                    {props.userEntitlements.entitlements.canToggleEnabledBusiness && <>
                                        {business.disabled ?
                                            <button className="btn btn-success"
                                                    onClick={() => enable(business.slug)}
                                            >{t('common.enable')}
                                            </button> :
                                            <button className="btn btn-danger"
                                                    onClick={() => disable(business.slug)}
                                            >{t('common.disable')}
                                            </button>
                                        }
                                    </>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <PaginationControl
                    className="float-end"
                    state={listResponse.pagination}
                    onPageChange={newPageId => setPageId(newPageId)}
                />

            </div>
        </div>
    )
}

export default BusinessListPage
