import React, {useEffect, useRef, useState} from "react"
import {autoRetry, failuresToToast} from "../service"
import {useTranslation} from "react-i18next"
import {useLogin} from "../common/CustomHooks"
import {toast} from "react-toastify"
import DelayedInput from "../common/DelayedInput"
import FileUpload from "../common/FileUpload"
import {confirm} from "../common/Forms"
import SignatureCanvas from 'react-signature-canvas'
import "./InspectorProfilePage.css"

/**
 * Show and edit the Inspector Profile.
 *
 * props.userEntitlements: The users entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 * props.refreshEntitlements(): The action to do when need to refresh the entitlements
 */
function InspectorProfilePage(props) {

    const {t} = useTranslation()

    const [activeSignatureTab, setActiveSignatureTab] = useState(1)
    const signatureCanvas = useRef(null)

    const [rbqLicenseNumber, setRbqLicenseNumber] = useState('')
    const [insuranceName, setInsuranceName] = useState('')
    const [insuranceCertificateNumber, setInsuranceCertificateNumber] = useState('')
    const [insuranceProfessionalCoveragePolicyNumber, setInsuranceProfessionalCoveragePolicyNumber] = useState('')
    const [insuranceCivilCoveragePolicyNumber, setInsuranceCivilCoveragePolicyNumber] = useState('')
    const [insuranceProfessionalCoverageByDisaster, setInsuranceProfessionalCoverageByDisaster] = useState('')
    const [insuranceProfessionalCoverageByPeriod, setInsuranceProfessionalCoverageByPeriod] = useState('')
    const [insuranceCivilCoverageByDisaster, setInsuranceCivilCoverageByDisaster] = useState('')
    const [insuranceCivilCoverageByPeriod, setInsuranceCivilCoverageByPeriod] = useState('')

    // Initial details
    useLogin(props.userEntitlements, props.needsLogin, '/user/inspectorProfile')

    function defaultValue(value, defaultValue) {
        return value ? value : defaultValue
    }

    useEffect(() => {
        setRbqLicenseNumber(defaultValue(props.userEntitlements?.inspectorProfile?.rbqLicenseNumber, ''))
        setInsuranceName(defaultValue(props.userEntitlements?.inspectorProfile?.insurance?.name, ''))
        setInsuranceCertificateNumber(defaultValue(props.userEntitlements?.inspectorProfile?.insurance?.certificateNumber, ''))
        setInsuranceProfessionalCoveragePolicyNumber(defaultValue(props.userEntitlements?.inspectorProfile?.insurance?.professionalCoveragePolicyNumber, ''))
        setInsuranceCivilCoveragePolicyNumber(defaultValue(props.userEntitlements?.inspectorProfile?.insurance?.civilCoveragePolicyNumber, ''))
        setInsuranceProfessionalCoverageByDisaster(defaultValue(props.userEntitlements?.inspectorProfile?.insurance?.professionalCoverageByDisaster, ''))
        setInsuranceProfessionalCoverageByPeriod(defaultValue(props.userEntitlements?.inspectorProfile?.insurance?.professionalCoverageByPeriod, ''))
        setInsuranceCivilCoverageByDisaster(defaultValue(props.userEntitlements?.inspectorProfile?.insurance?.civilCoverageByDisaster, ''))
        setInsuranceCivilCoverageByPeriod(defaultValue(props.userEntitlements?.inspectorProfile?.insurance?.civilCoverageByPeriod, ''))
    }, [JSON.stringify(props.userEntitlements?.inspectorProfile)])

    if (!props.userEntitlements || !props.userEntitlements.inspectorProfile) {
        return <></>
    }

    function showEditFullName() {
        const newFullName = window.prompt(t('user.inspectorProfile.requestUpdateFullNamePrompt'), props.userEntitlements.inspectorProfile.fullName)
        if (newFullName) {
            autoRetry(t('common.requestUpdate'), () => window.service.userInspectorProfileUpdateFullName(newFullName), 5).then(response => {
                toast.success(response.data.item)
                props.refreshEntitlements()
            })
        }
    }

    function updateRbqLicenseNumber(newValue) {
        failuresToToast(t('common.edit'), () => window.service.userInspectorProfileUpdateRbqLicenseNumber(newValue), 5).then(response => {
            if (response.data.success) {
                setRbqLicenseNumber(newValue)
            }
        })
    }

    function updateInsuranceName(newValue) {
        failuresToToast(t('common.edit'), () => window.service.userInspectorProfileUpdateInsuranceName(newValue), 5).then(response => {
            if (response.data.success) {
                setInsuranceName(newValue)
            }
        })
    }

    function updateInsuranceCertificateNumber(newValue) {
        failuresToToast(t('common.edit'), () => window.service.userInspectorProfileUpdateInsuranceCertificateNumber(newValue), 5).then(response => {
            if (response.data.success) {
                setInsuranceCertificateNumber(newValue)
            }
        })
    }

    function updateInsuranceProfessionalCoveragePolicyNumber(newValue) {
        failuresToToast(t('common.edit'), () => window.service.userInspectorProfileUpdateInsuranceProfessionalCoveragePolicyNumber(newValue), 5).then(response => {
            if (response.data.success) {
                setInsuranceProfessionalCoveragePolicyNumber(newValue)
            }
        })
    }

    function updateInsuranceCivilCoveragePolicyNumber(newValue) {
        failuresToToast(t('common.edit'), () => window.service.userInspectorProfileUpdateInsuranceCivilCoveragePolicyNumber(newValue), 5).then(response => {
            if (response.data.success) {
                setInsuranceCivilCoveragePolicyNumber(newValue)
            }
        })
    }

    function updateInsuranceProfessionalCoverageByDisaster(newValue) {
        failuresToToast(t('common.edit'), () => window.service.userInspectorProfileUpdateInsuranceProfessionalCoverageByDisaster(newValue), 5).then(response => {
            if (response.data.success) {
                setInsuranceProfessionalCoverageByDisaster(newValue)
            }
        })
    }

    function updateInsuranceProfessionalCoverageByPeriod(newValue) {
        failuresToToast(t('common.edit'), () => window.service.userInspectorProfileUpdateInsuranceProfessionalCoverageByPeriod(newValue), 5).then(response => {
            if (response.data.success) {
                setInsuranceProfessionalCoverageByPeriod(newValue)
            }
        })
    }

    function updateInsuranceCivilCoverageByDisaster(newValue) {
        failuresToToast(t('common.edit'), () => window.service.userInspectorProfileUpdateInsuranceCivilCoverageByDisaster(newValue), 5).then(response => {
            if (response.data.success) {
                setInsuranceCivilCoverageByDisaster(newValue)
            }
        })
    }

    function updateInsuranceCivilCoverageByPeriod(newValue) {
        failuresToToast(t('common.edit'), () => window.service.userInspectorProfileUpdateInsuranceCivilCoverageByPeriod(newValue), 5).then(response => {
            if (response.data.success) {
                setInsuranceCivilCoverageByPeriod(newValue)
            }
        })
    }

    function userProfileSignatureClearDraw() {
        signatureCanvas.current.clear()
    }

    function userProfileSignatureUpdateDraw() {
        const canvas = signatureCanvas.current.getTrimmedCanvas()
        canvas.toBlob((blob) => {
            const file = new File([blob], 'signature.jpg', {type: 'image/jpeg'})
            failuresToToast(t('common.edit'), () => window.service.userProfileSignatureUpdate(file), false).then(response => {
                if (response.data.item) {
                    signatureCanvas.current.clear()
                    props.refreshEntitlements()
                }
            })
        }, 'image/jpeg')
    }

    function userProfileSignatureUpdateUpload(file) {
        return failuresToToast(t('common.edit'), () => window.service.userProfileSignatureUpdate(file), false).then(response => {
            if (response.data.item) {
                props.refreshEntitlements()
            }
        })
    }

    function userProfileSignatureDelete() {
        confirm(t('user.inspectorProfile.signatureDeleteConfirm'), () =>
            failuresToToast(t('common.delete'), () => window.service.userProfileSignatureDelete()).then(response => {
                props.refreshEntitlements()
            })
        )
    }

    function changeActiveSignatureTab(id) {
        setActiveSignatureTab(id)
        setTimeout(() => document.getElementById('signatureTabs').scrollIntoView(), 100)
    }

    return (
        <div className="row">
            <div className="col">

                <h2>{t('user.inspectorProfile.title')}</h2>

                <div className="clearfix"></div>

                <table className="table">
                    <tbody>
                    <tr>
                        <th>{t('user.inspectorProfile.email')}</th>
                        <td>{props.userEntitlements.userEmail}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>{t('user.inspectorProfile.fullName')}</th>
                        <td>{props.userEntitlements.inspectorProfile.fullName}</td>
                        <td>
                            <button className="btn btn-success" type="button"
                                    onClick={() => showEditFullName()}>{t('common.requestUpdate')}</button>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('user.inspectorProfile.rbqLicenseNumber')}</th>
                        <td>
                            <DelayedInput
                                name="rbqLicenseNumber"
                                type="text"
                                className="form-control"
                                initialValue={rbqLicenseNumber}
                                waitTimeInMs={500}
                                onChange={newValue => updateRbqLicenseNumber(newValue)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>{t('user.inspectorProfile.insurance.title')}</th>
                        <td colSpan={2}>
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th>{t('user.inspectorProfile.insurance.name')}</th>
                                    <td>
                                        <DelayedInput
                                            name="insuranceName"
                                            type="text"
                                            className="form-control"
                                            initialValue={insuranceName}
                                            waitTimeInMs={500}
                                            onChange={newValue => updateInsuranceName(newValue)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('user.inspectorProfile.insurance.certificateNumber')}</th>
                                    <td>
                                        <DelayedInput
                                            name="insuranceCertificateNumber"
                                            type="text"
                                            className="form-control"
                                            initialValue={insuranceCertificateNumber}
                                            waitTimeInMs={500}
                                            onChange={newValue => updateInsuranceCertificateNumber(newValue)}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <th>{t('user.inspectorProfile.insurance.policyNumber')}</th>
                        <td colSpan={2}>
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th>{t('user.inspectorProfile.insurance.professionalCoverage')}</th>
                                    <td>
                                        <DelayedInput
                                            name="professionalCoveragePolicyNumber"
                                            type="text"
                                            className="form-control"
                                            initialValue={insuranceProfessionalCoveragePolicyNumber}
                                            waitTimeInMs={500}
                                            onChange={newValue => updateInsuranceProfessionalCoveragePolicyNumber(newValue)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('user.inspectorProfile.insurance.civilCoverage')}</th>
                                    <td>
                                        <DelayedInput
                                            name="civilCoveragePolicyNumber"
                                            type="text"
                                            className="form-control"
                                            initialValue={insuranceCivilCoveragePolicyNumber}
                                            waitTimeInMs={500}
                                            onChange={newValue => updateInsuranceCivilCoveragePolicyNumber(newValue)}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <th>{t('user.inspectorProfile.insurance.coverage')}</th>
                        <td colSpan={2}>
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th>{t('user.inspectorProfile.insurance.professionalCoverage')}</th>
                                    <td>
                                        {t('user.inspectorProfile.insurance.byDisaster')} ($)<br/>
                                        <DelayedInput
                                            name="professionalCoverageByDisaster"
                                            type="number"
                                            className="form-control"
                                            initialValue={insuranceProfessionalCoverageByDisaster}
                                            waitTimeInMs={500}
                                            onChange={newValue => updateInsuranceProfessionalCoverageByDisaster(newValue)}
                                        /><br/>
                                        {t('user.inspectorProfile.insurance.byPeriod')} ($)<br/>
                                        <DelayedInput
                                            name="professionalCoverageByPeriod"
                                            type="number"
                                            className="form-control"
                                            initialValue={insuranceProfessionalCoverageByPeriod}
                                            waitTimeInMs={500}
                                            onChange={newValue => updateInsuranceProfessionalCoverageByPeriod(newValue)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('user.inspectorProfile.insurance.civilCoverage')}</th>
                                    <td>
                                        {t('user.inspectorProfile.insurance.byDisaster')} ($)<br/>
                                        <DelayedInput
                                            name="civilCoverageByDisaster"
                                            type="number"
                                            className="form-control"
                                            initialValue={insuranceCivilCoverageByDisaster}
                                            waitTimeInMs={500}
                                            onChange={newValue => updateInsuranceCivilCoverageByDisaster(newValue)}
                                        /><br/>
                                        {t('user.inspectorProfile.insurance.byPeriod')} ($)<br/>
                                        <DelayedInput
                                            name="civilCoverageByPeriod"
                                            type="number"
                                            className="form-control"
                                            initialValue={insuranceCivilCoverageByPeriod}
                                            waitTimeInMs={500}
                                            onChange={newValue => updateInsuranceCivilCoverageByPeriod(newValue)}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <th>{t('user.inspectorProfile.signature')}</th>
                        <td>
                            {props.userEntitlements?.inspectorProfile?.signatureDate &&
                                <>
                                    <img
                                        src={`/user/${props.userEntitlements?.userEmail}/profile/signature?maxWidth=600&id=${props.userEntitlements?.inspectorProfile?.signatureDate}`}
                                        alt="Signature"/>
                                    <button className="btn btn-danger"
                                            onClick={() => userProfileSignatureDelete()}
                                    >{t('common.delete')}
                                    </button>
                                </>
                            }

                            <ul id="signatureTabs" className="nav nav-tabs">
                                <li className="nav-item">
                                    <button
                                        className={"nav-link " + (activeSignatureTab === 1 ? 'active' : '')}
                                        onClick={() => changeActiveSignatureTab(1)}>
                                        {t('user.inspectorProfile.signatureDraw')}
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={"nav-link " + (activeSignatureTab === 2 ? 'active' : '')}
                                        onClick={() => changeActiveSignatureTab(2)}>
                                        {t('user.inspectorProfile.signatureUpload')}
                                    </button>
                                </li>
                            </ul>

                            <br/>

                            {activeSignatureTab === 1 &&
                                <div className="signatureDraw">
                                    <SignatureCanvas
                                        ref={signatureCanvas}
                                        penColor="black"
                                        backgroundColor="white"
                                        canvasProps={{width: 500, height: 200}}
                                    />
                                    <br/>
                                    <div className="float-end">
                                        <button type="button" className="btn btn-outline-primary"
                                                onClick={userProfileSignatureClearDraw}>{t('common.clear')}</button>
                                        <br/>
                                        <button type="button" className="btn btn-primary"
                                                onClick={userProfileSignatureUpdateDraw}>{t('common.update')}</button>
                                    </div>
                                </div>
                            }
                            {activeSignatureTab === 2 &&
                                <FileUpload
                                    name="signature"
                                    accept=".jpg, .jpeg"
                                    multi={false}
                                    uploadFile={userProfileSignatureUpdateUpload}
                                />
                            }
                        </td>
                    </tr>

                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default InspectorProfilePage
