import {NavLink, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {failuresToToast} from "../service";
import {useTranslation} from "react-i18next";
import {useLogin} from "../common/CustomHooks";
import FileUpload from "../common/FileUpload";

/**
 * The business viewing page.
 *
 * props.userEntitlements: The users entitlements
 * props.setBusinessSlug(slug): Set the business to get the entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function BusinessViewPage(props) {

    const {slug} = useParams()

    const {t} = useTranslation()

    const [itemResponse, setItemResponse] = useState({})
    const [item, setItem] = useState({})

    useLogin(props.userEntitlements, props.needsLogin, '/business/' + slug)

    useEffect(() => {
        if (slug && props.userEntitlements && props.userEntitlements.isLoggedIn) {
            failuresToToast(t('business.context'), () => window.service.businessGet(slug), false).then(response => {
                setItemResponse(response.data)
                setItem(response.data.item)
            })
        }
    }, [slug, props.userEntitlements])

    useEffect(() => {
        props.setBusinessSlug(slug)
    }, [slug])

    function businessLogoUpdate(file) {
        return failuresToToast(t('business.context'), () => window.service.businessLogoUpdate(slug, file), false).then(response => {
            if (response.data.item) {
                setItem(response.data.item)
            }
        })
    }

    const canUpdateAnything = props.userEntitlements?.entitlements?.canUpdateBusinessDetails || props.userEntitlements?.entitlements?.canUpdateBusinessAdministrator || props.userEntitlements?.entitlements?.canUpdateBusinessEmployeesAndInspectors

    return (
        <div className="row">
            <div className="col">

                <h2>{t('business.title')}</h2>
                <h3>{t('business.details')}</h3>

                {canUpdateAnything &&
                    <NavLink to={`/business-edit/${slug}`}
                             className="btn btn-success float-end">{t('common.edit')}
                    </NavLink>
                }

                <div className="clearfix"></div>

                <table className="table">
                    <tbody>
                    <tr>
                        <th>{t('business.name')}</th>
                        <td>{item.name}</td>
                    </tr>
                    <tr>
                        <th>{t('business.neq')}</th>
                        <td>{item.neq}</td>
                    </tr>
                    <tr>
                        <th>{t('business.address')}</th>
                        <td>{item.address?.full}</td>
                    </tr>
                    <tr>
                        <th>{t('business.phoneLocal')}</th>
                        <td>{item.phoneLocal}</td>
                    </tr>
                    <tr>
                        <th>{t('business.phoneNoFees')}</th>
                        <td>{item.phoneNoFees}</td>
                    </tr>
                    <tr>
                        <th>{t('business.email')}</th>
                        <td><a href={'mailto:' + item.email}>{item.email}</a></td>
                    </tr>
                    <tr>
                        <td colSpan="2"></td>
                    </tr>
                    <tr>
                        <th>{t('business.tps')}</th>
                        <td>{item.tps}</td>
                    </tr>
                    <tr>
                        <th>{t('business.tvq')}</th>
                        <td>{item.tvq}</td>
                    </tr>
                    </tbody>
                </table>

                <h3>{t('business.logo.title')}</h3>

                {item.logo &&
                    <img src={`/business/${slug}/logo?maxWidth=128&id=${item.logo.id}`} alt="Logo"/>
                }

                {props.userEntitlements?.entitlements?.canUpdateBusinessLogo &&
                    <FileUpload
                        name="logo"
                        accept=".jpg, .jpeg"
                        multi={false}
                        uploadFile={businessLogoUpdate}
                    />
                }

                <h3>{t('business.moduleAccess.title')}</h3>

                <table className="table">
                    <tbody>
                    <tr>
                        <th>{t('business.moduleAccess.register')}</th>
                        <td><input type="checkbox" checked={item.moduleAccess?.register} disabled={true}/></td>
                    </tr>
                    <tr>
                        <th>{t('business.moduleAccess.project')}</th>
                        <td><input type="checkbox" checked={item.moduleAccess?.project} disabled={true}/></td>
                    </tr>
                    </tbody>
                </table>

                <h3>{t('business.administrators')}</h3>

                <table className="table">
                    <tbody>
                    {item.administrators?.map((administrator, index) => {
                        return (
                            <tr key={index}>
                                <td>{administrator}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                <h3>{t('business.employees')}</h3>

                <table className="table">
                    <tbody>
                    {item.employees?.map((employee, index) => {
                        return (
                            <tr key={index}>
                                <td>{employee}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                <h3>{t('business.inspectors')}</h3>

                <table className="table">
                    <tbody>
                    {item.inspectors?.map((inspector, index) => {
                        return (
                            <tr key={index}>
                                <td>{inspector}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default BusinessViewPage
