import './ProjectStickyMenuComponent.css'
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import FindingCreateEditComponent from "./FindingCreateEditComponent";
import ProjectWarningsBadge from "./ProjectWarningsBadge";
import {confirm} from "../common/Forms";
import {failuresToToast} from "../service";

/**
 * To create or edit a Report on a Project.
 *
 * props.listByType: The list of internal list by type
 * props.internalListById : The items per their id
 * props.project: The project
 * props.updated(project): The action to do when the finding is created or updated
 */
function ProjectStickyMenuComponent(props) {

    const {t} = useTranslation()

    const [showCreateFinding, setShowCreateFinding] = useState(false)
    const [clickedGenerateReport, setClickedGenerateReport] = useState(false)

    function scrollTo(gotoId) {

        // Collapse all sections
        const sections = document.getElementsByClassName('collapse')
        for (let i = 0; i < sections.length; i++) {
            sections[i].classList.remove('show')
        }

        // Uncollapse if collapsed
        const uncollapseId = gotoId + 'Section'
        const uncollapseElement = document.getElementById(uncollapseId)
        if (uncollapseElement) {
            uncollapseElement.classList.add('show')
        }

        // Get the height of the menu
        const projectMenuHeight = document.getElementById('projectMenu').offsetHeight

        // Get the top position of the element with the id
        const top = document.getElementById(gotoId).offsetTop

        // Scroll
        window.scrollTo(0, top - projectMenuHeight)

    }

    function sendReport() {
        confirm(t('project.sendReportConfirm'), () =>
            failuresToToast(t('project.sendReport'), () => window.service.projectSendReport(props.project.id))
        )
    }

    function updated(project) {
        setShowCreateFinding(false)
        props.updated && props.updated(project)
    }

    return <div id="projectMenu" className="sticky-top">
        <div>
            <ProjectWarningsBadge project={props.project}/>
        </div>
        <div>
            <a onClick={() => scrollTo('details')}><i className="bi bi-info-square menuIcon"/></a>
            <a onClick={() => scrollTo('applicants')}><i className="bi bi-person-exclamation menuIcon"/></a>
            <a onClick={() => scrollTo('purposeOfTransaction')}><i className="bi bi-receipt menuIcon"/></a>
            <a onClick={() => scrollTo('residentialBuildingDescription')}><i className="bi bi-house-gear menuIcon"/></a>
            <a onClick={() => scrollTo('owners')}><i className="bi bi-person menuIcon"/></a>
            <a onClick={() => scrollTo('residentialBuildingPart')}><i className="bi bi-eye menuIcon"/></a>
            <a onClick={() => scrollTo('dependencies')}><i className="bi bi-building-gear menuIcon"/></a>
            <a onClick={() => scrollTo('presentPersons')}><i className="bi bi-person-check menuIcon"/></a>
            <a onClick={() => scrollTo('weather')}><i className="bi bi-cloud-sun menuIcon"/></a>
            <a onClick={() => scrollTo('specialConditions')}><i className="bi bi-clipboard menuIcon"/></a>
            <a onClick={() => scrollTo('documents')}><i className="bi bi-file-earmark-word menuIcon"/></a>
            <a onClick={() => scrollTo('allPhotos')}><i className="bi bi-images menuIcon"/></a>
            <a onClick={() => scrollTo('sections')}><i className="bi bi-journal-text menuIcon"/></a>
        </div>
        <div>
            <button className="btn btn-success float-end"
                    disabled={!props.project.inspectionReportPrintedDate && !clickedGenerateReport}
                    onClick={sendReport}
                    title={t('project.sendReport')}
            >
                <i className="bi bi-envelope-at"></i>
            </button>
        </div>
        <div>
            <a className="btn btn-success float-end"
               href={'/project/' + props.project.id + '/report.pdf'}
               target={'_blank'}
               onClick={() => setClickedGenerateReport(true)}
               title={t('project.generateReport')}
            >
                <i className="bi bi-file-earmark-pdf-fill"></i>
            </a>
        </div>
        <div>
            <a className="btn btn-success float-end"
               href={'/project/' + props.project.id + '/report-draft.pdf'}
               target={'_blank'}
               title={t('project.generateDraftReport')}
            >
                <i className="bi bi-file-earmark-pdf"></i>
            </a>
        </div>
        <div>
            <button className="btn btn-success"
                    onClick={() => setShowCreateFinding(true)}
                    title={t('project.createFinding')}
            >
                <i className="bi bi-pencil-square"></i>
            </button>
        </div>
        {showCreateFinding && <div>
            <FindingCreateEditComponent
                listByType={props.listByType}
                internalListById={props.internalListById}
                project={props.project}
                updated={updated}
                cancel={() => setShowCreateFinding(false)}
            />
        </div>
        }
    </div>
}

export default ProjectStickyMenuComponent
