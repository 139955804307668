import React from "react";
import SearchInternalListLocal from "./SearchInternalListLocal";
import {useTranslation} from "react-i18next";
import {cloneObject} from "./ObjectUtils";

/**
 * A component to edit a list of internal list with data locally.
 * - listByType : The list of internal list by type
 * - name : The name for the input
 * - type : The type of internal list (See ListType)
 * - precisionType : The type of internal list for the precision (See ListType)
 * - selectedEntries : The currently selected entries
 * - onChangeSelectedEntries : A callback function(newEntries)
 */
function ListSearchInternalListWithPrecisionLocal(props) {

    const {t} = useTranslation()

    const list = props.selectedEntries ? props.selectedEntries : []
    if (list.length === 0) {
        list.push({
            id: '',
            precisionId: '',
        })
    }

    const ready = props.listByType && props.listByType[props.type] && props.listByType[props.precisionType]
    if (!ready) {
        return <></>
    }

    function updateIdPart(newItem, index) {
        const newEntries = cloneObject(props.selectedEntries)
        newEntries[index].id = newItem?.id
        props.onChangeSelectedEntries(newEntries)
    }

    function updatePrecisionIdPart(newItem, index) {
        const newEntries = cloneObject(props.selectedEntries)
        newEntries[index].precisionId = newItem?.id
        props.onChangeSelectedEntries(newEntries)
    }

    function addValue() {
        const newEntries = cloneObject(props.selectedEntries)
        newEntries.push({
            id: '',
            precisionId: '',
        })
        props.onChangeSelectedEntries(newEntries)
    }

    function removeValue(index) {
        const newEntries = [...props.selectedEntries]
        newEntries.splice(index, 1)
        props.onChangeSelectedEntries(newEntries)
    }

    return (
        <>
            <table className="table">
                <tbody>
                {list.map((entry, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <SearchInternalListLocal
                                    listByType={props.listByType}
                                    name={props.name}
                                    type={props.type}
                                    selectedId={entry.id}
                                    onChangeSelectedItem={(newItem) => updateIdPart(newItem, index)}
                                />
                                {props.listByType[props.precisionType].filter(p => p.dependOnId === entry.id).length > 0 &&
                                    <div className="col-12 col-sm-6">
                                        <strong>{t('project.finding.precisionId')}</strong>
                                        <br/>
                                        <SearchInternalListLocal
                                            listByType={props.listByType}
                                            name={`${props.name}-precision`}
                                            type={props.precisionType}
                                            selectedId={entry.precisionId}
                                            dependOnId={entry.id}
                                            onChangeSelectedItem={(newItem) => updatePrecisionIdPart(newItem, index)}
                                        />
                                    </div>
                                }
                            </td>
                            <td>
                                <button className="btn btn-danger float-end" type="button"
                                        onClick={() => removeValue(index)}>
                                    X
                                </button>

                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            <button className={'btn btn-success'} type="button" onClick={() => addValue()}>
                +
            </button>
        </>
    )
}

export default ListSearchInternalListWithPrecisionLocal;
