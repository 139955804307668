import React, {useEffect, useState} from "react";
import {autoRetry} from "../service";
import {useTranslation} from "react-i18next";
import PaginationControl from "../common/PaginationControl";
import {useLogin} from "../common/CustomHooks";
import BillCreateDialog from "./BillCreateDialog";

/**
 * The project billing listing page.
 *
 * props.userEntitlements: The users entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function BillingProjectBillingListPage(props) {

    const {t} = useTranslation()

    // Initial details
    const [listResponse, setListResponse] = useState([])
    const [pageId, setPageId] = useState(1)
    const [onlyCreated, setOnlyCreated] = useState(true)
    const [notBilled, setNotBilled] = useState(true)
    const [partiallyBilled, setPartiallyBilled] = useState(true)
    const [billed, setBilled] = useState(true)

    const [create, setCreate] = useState(null)

    useLogin(props.userEntitlements, props.needsLogin, '/billing/projectBilling')

    useEffect(() => {
        if (props.userEntitlements && props.userEntitlements.isLoggedIn) {
            refreshPage(pageId)
        }
    }, [props.userEntitlements, pageId, onlyCreated, notBilled, partiallyBilled, billed])

    if (!props.userEntitlements) {
        return <></>
    }

    function refreshPage(pageId) {
        setPageId(pageId)
        autoRetry(t('projectBilling.context'), () => window.service.projectBillingFindAllByState(pageId, onlyCreated, notBilled, partiallyBilled, billed), 5).then(response => {
            setListResponse(response.data)
        })
    }

    return (
        <div className="row">
            <div className="col">

                {create && <BillCreateDialog
                    initial={create}
                    onClose={shouldRefresh => {
                        setCreate(null)
                        if (shouldRefresh) {
                            refreshPage(pageId)
                        }
                    }}
                />}

                <h2>{t('projectBilling.title-plural')}</h2>

                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch"
                           id="onlyCreated"
                           name="onlyCreated"
                           checked={onlyCreated}
                           onChange={e => setOnlyCreated(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="onlyCreated">{t('projectBilling.onlyCreated')}</label>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch"
                           id="notBilled"
                           name="notBilled"
                           checked={notBilled}
                           onChange={e => setNotBilled(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="notBilled">{t('projectBilling.notBilled')}</label>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch"
                           id="partiallyBilled"
                           name="partiallyBilled"
                           checked={partiallyBilled}
                           onChange={e => setPartiallyBilled(e.target.checked)}
                    />
                    <label className="form-check-label"
                           htmlFor="partiallyBilled">{t('projectBilling.partiallyBilled')}</label>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch"
                           id="billed"
                           name="billed"
                           checked={billed}
                           onChange={e => setBilled(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="billed">{t('projectBilling.billed')}</label>
                </div>

                <br/>

                <PaginationControl
                    className="float-end"
                    state={listResponse.pagination}
                    onPageChange={newPageId => setPageId(newPageId)}
                />

                <div className="clearfix"></div>

                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>{t('projectBilling.period')}</th>
                            <th>{t('projectBilling.business')}</th>
                            <th>{t('projectBilling.projectsCreated')}</th>
                            <th>{t('projectBilling.projectsGenerated')}</th>
                            <th>{t('projectBilling.projectsBilled')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listResponse.items && listResponse.items.map(billingRow => (
                            <tr key={billingRow.period + billingRow.business.id}>
                                <td>{billingRow.period}</td>
                                <td>{billingRow.business.name}</td>
                                <td>{billingRow.projectsCreated}</td>
                                <td>{billingRow.projectsGenerated}
                                    {billingRow.projectsGenerated > 0 &&
                                        <button className="btn btn-primary btn-sm float-end"
                                                onClick={() => setCreate({
                                                    period: billingRow.period,
                                                    business: billingRow.business,
                                                    units: billingRow.projectsGenerated,
                                                })}
                                        >{t('projectBilling.bill')}</button>
                                    }
                                </td>
                                <td>{billingRow.projectsBilled}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <PaginationControl
                    className="float-end"
                    state={listResponse.pagination}
                    onPageChange={newPageId => setPageId(newPageId)}
                />

            </div>
        </div>
    )
}

export default BillingProjectBillingListPage
