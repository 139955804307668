import TypeAhead from "./TypeAhead";

/**
 * Typeahead for inspectors that the user can use.
 * - name : The name for the input
 * - outsideChangedSelectedId : (optional) If you want to select from outside
 *
 * - businessId : (optional) to search inspectors in a single business
 *
 * - selectFirstInitially : If true, the first item will be selected initially
 * - onChangeSelectedItem : A callback function(newInspector)
 */
function SearchInspector(props) {

    function makeSearch(searchValue) {
        return window.service.businessInspectorFindAll({
            businessId: props.businessId,
            search: searchValue,
        })
    }

    return (
        <TypeAhead
            name={props.name}
            outsideChangedSelectedId={props.outsideChangedSelectedId}
            onChangeSelectedItem={(newInspector) => props.onChangeSelectedItem(newInspector)}
            makeSearch={makeSearch}
            initialSearch={true}
            selectFirstInitially={props.selectFirstInitially}
            size="60"
            toId={(inspector) => inspector?.email}
            toDisplay={(inspector) => `${inspector.fullName} (${inspector.email})`}
            toFieldSearch={(inspector) => inspector.fullName}
            forceRefresh={props.businessId}
        />
    )

}

export default SearchInspector
