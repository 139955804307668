import React, {useEffect, useState} from "react";
import {failuresToToast} from "../service";
import './TypeAhead.css'

/**
 * Generic Typeahead.
 * - name : The name for the input
 * - outsideChangedSelectedId : (optional) If you want to select from outside
 *
 * - onChangeSelectedItem : A callback function(newItem) when an item is selected
 * - onChangeSearchValue : A callback function(newSearchValue) when the search bar text changes
 *
 * - makeSearch : A callback that will call a service for the items function(searchValue):Promise<List<String>> or Promise with the list in "reponse.data.items"
 *
 * - initialSearch : If true, the search will be called on the first render
 * - selectFirstInitially : If true, the first item will be selected initially
 * - size : The size of the input
 *
 * - toId : A function to convert the item to an id (used for the listing's key)
 * - toDisplay : A function to convert the item to a display value (what is displayed in the popup)
 * - toFieldSearch : A function to convert the item to what will be displayed in the search field once selected (what is displayed in the field when selected)
 *
 * - forceRefresh : A value that when it changes, it forces an items refresh
 */
function TypeAhead(props) {

    const [items, setItems] = useState([])
    const [showItems, setShowItems] = useState(false)
    const [transitionEnded, setTransitionEnded] = useState(true)
    const [neverAutoSelected, setNeverAutoSelected] = useState(true)

    const [searchValue, setSearchValue] = useState('')
    const [selectedItem, setSelectedItem] = useState(null)

    const [lastSearchValue, setLastSearchValue] = useState(null)
    const [lastSelectedItem, setLastSelectedItem] = useState(null)
    const [lastForceRefresh, setLastForceRefresh] = useState(null)

    const [needsRefresh, setNeedsRefresh] = useState(1)

    // Do the initial search
    useEffect(() => {
            if (props.initialSearch) {
                updateItems()
            }
        },  // eslint-disable-next-line
        [])

    // Limit the service calls
    useEffect(() => {
            const timoutInstance = setTimeout(() => updateItems(), 300)
            return () => clearTimeout(timoutInstance)
        },  // eslint-disable-next-line
        [needsRefresh, props.forceRefresh])

    // Update the selected item from outside
    useEffect(() => {

            if (!props.outsideChangedSelectedId) {
                return
            }

            // Already the right one
            if (selectedItem && props.toId(selectedItem) === props.outsideChangedSelectedId) {
                return
            }

            // Search in current list
            const item = items.find(i => props.toId(i) === props.outsideChangedSelectedId)
            if (item) {
                console.log(`[${props.name}] Outside selected`, item)
                select(item)
            } else {
                // Do an empty search and find it
                const currentSearchValue = ''
                console.log(`[${props.name}] Do an empty search to find the outside selected item`)

                failuresToToast(
                    `Search ${props.name}`,
                    () => props.makeSearch(currentSearchValue),
                    false
                )
                    .then((newItemsOrResponse) => {

                        setLastSearchValue(currentSearchValue)

                        let items = []

                        if (newItemsOrResponse.data.items) {
                            items = newItemsOrResponse.data.items
                        }

                        setItems(items)

                        const item = items.find(i => props.toId(i) === props.outsideChangedSelectedId)
                        if (item) {
                            console.log(`[${props.name}] Outside selected`, item)
                            select(item)
                        }

                    })
            }

        },  // eslint-disable-next-line
        [props.outsideChangedSelectedId])


    // Update the internal state
    useEffect(() => {
            updateInternalState()
        },  // eslint-disable-next-line
        [selectedItem, items, lastSearchValue, searchValue])

    function select(item) {
        console.log(`[${props.name}] Select`, item)
        setSelectedItem(item)
        if (item) {
            setSearchValue(props.toFieldSearch(item))
        }
    }

    function updateItems() {

        const wasForced = lastForceRefresh !== props.forceRefresh
        if (wasForced) {
            setLastForceRefresh(props.forceRefresh)
        }

        if (!wasForced && lastSearchValue === searchValue) {
            console.log(`[${props.name}] Skipping search (same last search)`)
            return
        }

        console.log(`[${props.name}] Search with ${searchValue}`)
        const currentSearchValue = searchValue

        failuresToToast(
            `Search ${props.name}`,
            () => props.makeSearch(searchValue),
            false
        )
            .then((newItemsOrResponse) => {

                setLastSearchValue(currentSearchValue)

                let items = []

                if (newItemsOrResponse.data.items) {
                    items = newItemsOrResponse.data.items

                    if (!selectedItem && props.selectFirstInitially && neverAutoSelected && newItemsOrResponse.data.items.length > 0) {
                        let i = newItemsOrResponse.data.items[0];
                        console.log(`[${props.name}] Auto-select initial`, i)
                        setNeverAutoSelected(false)
                        select(i)
                    }
                }

                setItems(items)

            })
    }

    function updateInternalState() {

        let currentSelectedItem = selectedItem

        // When selected, ensure still the right text
        if (currentSelectedItem && props.toFieldSearch(selectedItem) !== props.searchValue) {
            currentSelectedItem = null
        }

        // Check if the current search fits one item
        if (!currentSelectedItem) {
            currentSelectedItem = items.find(i => props.toFieldSearch(i) === searchValue)
        }

        // Send events
        if (currentSelectedItem !== lastSelectedItem) {
            if (props.toId(currentSelectedItem) !== props.toId(lastSelectedItem)) {
                console.log(`[${props.name}] updateInternalState changed selected`, lastSelectedItem, ' => ', currentSelectedItem)
                select(currentSelectedItem)
                props.onChangeSelectedItem && props.onChangeSelectedItem(currentSelectedItem)
            }

            setLastSelectedItem(currentSelectedItem)
        }
        if (lastSearchValue !== searchValue) {
            props.onChangeSearchValue && props.onChangeSearchValue(searchValue)
        }

    }

    function changeShowItems(newValue) {
        setTransitionEnded(false)
        setShowItems(newValue)
    }

    return (
        <div className="TypeAheadSearch"
             onFocus={() => changeShowItems(true)}
             onBlur={() => changeShowItems(false)}
        >
            {selectedItem && <i className="bi bi-check-circle text-success"></i> ||
                <i className="bi bi-check-circle text-info"></i>}
            <input className="form-control input-text-padding-left"
                   type="text"
                   name={props.name}
                   value={searchValue}
                   onChange={(e) => {
                       setSearchValue(e.target.value)
                       setNeedsRefresh((prevNeedsRefresh => prevNeedsRefresh + 1))
                   }}
                   size={props.size}
                   autoComplete="off"
            />
            <div className={`Items ${showItems ? 'showItems' : transitionEnded ? 'hideItems' : ''}`}
                 onTransitionEnd={() => setTransitionEnded(true)}
            >
                {items.map(i => (
                        <div key={props.toId(i)}
                             onClick={() => {
                                 console.log(`[${props.name}] Clicked selected`, i)
                                 select(i)
                             }}
                        >
                            {props.toDisplay(i)}
                        </div>
                    )
                )}
            </div>
        </div>
    )

}

export default TypeAhead
