import TypeAhead from "./TypeAhead";
import {toLangOnly} from "./LangUtils";
import {useTranslation} from "react-i18next";

/**
 * Typeahead for internal list.
 * - name : The name for the input
 * - type : The type of internal list (See ListType)
 * - dependOnId : (optional) The id of the dependency
 * - selectedId : (optional) If you want to select from outside
 * - onChangeSelectedItem : A callback function(newItem)
 */
function SearchInternalList(props) {

    const {t, i18n} = useTranslation()

    function toValue(internalList) {
        return internalList?.value[toLangOnly(i18n.language)];
    }

    return (
        <TypeAhead
            name={props.name}
            outsideChangedSelectedId={props.selectedId}
            onChangeSelectedItem={(newInternalList) => props.onChangeSelectedItem(newInternalList)}
            makeSearch={(searchValue) => window.service.internalListFindAll(props.type, props.dependOnId, searchValue)}
            initialSearch={true}
            selectFirstInitially={false}
            size="60"
            toId={(internalList) => internalList?.id}
            toDisplay={(internalList) => toValue(internalList)}
            toFieldSearch={(internalList) => toValue(internalList)}
            forceRefresh={props.dependOnId}
        />
    )

}

export default SearchInternalList
