import {useEffect} from "react"
import {useNavigate} from "react-router-dom";

export function useInterval(theFunction, intervalInMs, depts = []) {

    useEffect(() => {
            const interval = setInterval(theFunction, intervalInMs)
            return () => clearInterval(interval)
        },  // eslint-disable-next-line
        depts)

}

export function useLogin(userEntitlements, needsLogin, redirectHash) {

    const navigate = useNavigate()

    useEffect(() => {
            if (userEntitlements) {
                if (!userEntitlements.isLoggedIn) {
                    console.log('useLogin: needs login. Will redirect to ' + redirectHash)
                    needsLogin(redirectHash)
                    return navigate('/login')
                }
            }
        },  // eslint-disable-next-line
        [userEntitlements])

}
