import React from "react";
import {useTranslation} from "react-i18next";

/**
 * A component to edit a list of text lines.
 *
 * props.values: The value list
 * props.disabled: (optional) If the component is disabled
 * props.maxItems: (optional) the max amount of items that can be set
 * props.rows: (optional) if more than 1, use a textarea instead of an input
 * props.onChange(newList): The action to do when the list changes
 * props.prefixRowLabel: (optional) A prefix to add to the row label
 */
function StringList(props) {

    const {t, i18n} = useTranslation()

    const list = props.values ? props.values : []
    const rows = props.rows ? props.rows : 1

    function updateValue(e, index) {
        const newList = [...props.values]
        newList[index] = e.target.value
        props.onChange(newList)
    }

    function addValue() {
        const newList = [...props.values]
        newList.push("")
        props.onChange(newList)
    }

    function removeValue(index) {
        const newList = [...props.values]
        newList.splice(index, 1)
        props.onChange(newList)
    }

    return (
        <>
            <table className="table">
                <tbody>
                {list.map((value, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <div className="input-group">
                                    {props.prefixRowLabel &&
                                        <div className="input-group-prepend">
                                            <span
                                                className="input-group-text">{t(props.prefixRowLabel)} {index + 1}
                                            </span>
                                        </div>
                                    }
                                    {rows === 1 &&
                                        <input type="text" className="form-control"
                                               autoComplete="off"
                                               value={value}
                                               onChange={(e) => updateValue(e, index)}
                                               disabled={props.disabled}
                                        />
                                    }
                                    {rows > 1 &&
                                        <textarea className="form-control" rows={rows}
                                                  value={value}
                                                  onChange={(e) => updateValue(e, index)}
                                                  disabled={props.disabled}
                                        />
                                    }

                                    {!props.disabled &&
                                        <button className="btn btn-danger" type="button"
                                                onClick={() => removeValue(index)}>
                                            X
                                        </button>
                                    }
                                </div>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {!props.disabled && (!props.maxItems || list.length < props.maxItems) &&
                <button className={'btn btn-success'} type="button" onClick={() => addValue()}>
                    +
                </button>
            }
        </>
    )
}

export default StringList;
