import React from "react";
import SearchInternalListLocal from "./SearchInternalListLocal";

/**
 * A component to edit a list of internal list with data locally.
 * - listByType : The list of internal list by type
 * - name : The name for the input
 * - type : The type of internal list (See ListType)
 * - dependOnId : (optional) The id of the dependency
 * - selectedIds : The currently selected ids
 * - startWithARow : (optional) If true, the list will start with a row
 * - onChangeSelectedItems : A callback function(newItems)
 */
function ListSearchInternalListLocal(props) {

    const list = props.selectedIds ? props.selectedIds : []

    const ready = props.listByType && props.listByType[props.type]
    if (!ready) {
        return <></>
    }

    if (props.startWithARow && list.length === 0) {
        addValue()
    }

    function updateValue(newItem, index) {
        const newIds = [...props.selectedIds]
        newIds[index] = newItem?.id
        props.onChangeSelectedItems(toItems(newIds))
    }

    function addValue() {
        const newIds = [...props.selectedIds]
        newIds.push("")
        props.onChangeSelectedItems(toItems(newIds))
    }

    function removeValue(index) {
        const newIds = [...props.selectedIds]
        newIds.splice(index, 1)
        props.onChangeSelectedItems(toItems(newIds))
    }

    function toItems(ids) {
        const items = []
        ids.forEach(id => {
            // Find the item in listByType
            const item = props.listByType[props.type].find(item => item.id === id)
            if (item) {
                items.push(item)
            } else {
                items.push({id: id})
            }
        })
        return items;
    }

    return (
        <>
            <table className="table">
                <tbody>
                {list.map((selectedId, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <SearchInternalListLocal
                                    listByType={props.listByType}
                                    name={props.name}
                                    type={props.type}
                                    selectedId={selectedId}
                                    onChangeSelectedItem={(newItem) => updateValue(newItem, index)}
                                />
                            </td>
                            <td>
                                <button className="btn btn-danger float-end" type="button"
                                        onClick={() => removeValue(index)}>
                                    X
                                </button>

                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            <button className={'btn btn-success'} type="button" onClick={() => addValue()}>
                +
            </button>
        </>
    )
}

export default ListSearchInternalListLocal;
