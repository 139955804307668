import React, {useState} from "react";
import {updateFormValue} from "../common/Forms";
import {NavLink, useNavigate} from "react-router-dom";
import {autoRetry} from "../service";
import FieldError from "../common/FieldError";
import GlobalErrorsAndWarnings from "../common/GlobalErrorsAndWarnings";
import {useTranslation} from "react-i18next";

/**
 * The login page.
 *
 * props.loginRedirectionHash: The hash to go to after the user logged in
 * props.onLogin(): The action to do when the user logged in
 */
function LoginPage(props) {

    const {t} = useTranslation()
    const navigate = useNavigate()

    const [form, setForm] = useState({
        username: '',
        password: '',
    })
    const [formResult, setFormResult] = useState()

    function login() {
        setFormResult(null)
        autoRetry(t('login.context'), () => window.service.userLogIn(form), 5).then(response => {
            setFormResult(response.data)
            if (response.data.success) {
                console.log("Login success. Going to the hash: " + props.loginRedirectionHash)
                props.onLogin()
                props.loginRedirectionHash ? navigate(props.loginRedirectionHash) : navigate('/')
            }
        })
    }

    return (<>
        <div className="row">
            <div className="col col-sm-6 col-md-4 col-lg-4">
                <h2>{t('login.title')}</h2>

                <GlobalErrorsAndWarnings formResult={formResult}/>

                <input type="text" className="form-control"
                       autoComplete="off"
                       name="username"
                       placeholder={t('login.username')}
                       value={form.username}
                       onChange={(e) => updateFormValue(e, form, setForm)}
                />
                <FieldError formResult={formResult} fieldName="username"/>

                <input type="password" className="form-control"
                       autoComplete="off"
                       name="password"
                       placeholder={t('login.password')}
                       value={form.password}
                       onChange={(e) => updateFormValue(e, form, setForm)}
                />
                <FieldError formResult={formResult} fieldName="password"/>

                <button className="btn btn-success float-end"
                        onClick={() => login()}
                >{t('login.login')}
                </button>

                <div className="clearfix"></div>

                <NavLink to="/login/create">{t('login.create')}</NavLink> | <NavLink
                to="/login/forgot">{t('login.forgot')}</NavLink>
            </div>
        </div>

    </>)
}

export default LoginPage
