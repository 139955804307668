import React from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

/**
 * The home page.
 *
 * props.userEntitlements: The users entitlements
 */
function HomePageLoggedIn(props) {

    const {t} = useTranslation()

    const userEntitlements = props.userEntitlements

    return (<>
        <div className="row">
            <div className="col">
                <h2>{t('home.dashboard')}</h2>
                <table className="table">
                    <thead>
                    <tr>
                        <th>{t('home.usersColumn')}</th>
                        <th>{t('home.projectsColumn')}</th>
                        <th>{t('home.registerColumn')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <strong>{t('home.profiles')}</strong><br/>
                            {userEntitlements.isAdmin && <>
                                <NavLink to="/login/create">{t('home.createPersonalProfile')}</NavLink><br/>
                                <NavLink to="/business-new">{t('home.createBusinessProfile')}</NavLink><br/>
                            </>}
                            {userEntitlements.inspectorProfile && <>
                                <NavLink to="/user/inspectorProfile">{t('home.inspectorProfile')}</NavLink><br/>
                            </>}
                            {userEntitlements.defaultBusinessSlug && <>
                                <NavLink
                                    to={`/business/${userEntitlements.defaultBusinessSlug}`}>{t('home.myBusinessProfile')}</NavLink><br/>
                            </>}

                            <strong>{t('home.lists')}</strong><br/>
                            <NavLink to="/business">{t('home.businessList')}</NavLink><br/>
                            {userEntitlements.isAdmin && <>
                                <NavLink
                                    to="/user?isAdmin=false&isInspector=false&isBusinessAdministrator=true&isBusinessEmployee=false">{t('home.userListBusinessAdministrators')}</NavLink>
                                <br/>
                                <NavLink
                                    to="/user?isAdmin=false&isInspector=true&isBusinessAdministrator=false&isBusinessEmployee=false">{t('home.userListInspectors')}</NavLink>
                                <br/>
                            </>}
                        </td>
                        <td>
                            {userEntitlements.entitlements?.canAccessProject && <>
                                <NavLink to="/project-new">{t('home.projectNew')}</NavLink><br/>
                            </>
                            }
                        </td>
                        <td>
                            {userEntitlements.entitlements?.canCreateRegister && <>
                                <NavLink to="/register-new">{t('home.registerNew')}</NavLink><br/>
                            </>
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>

                <p>{t('home.reports')}</p>
            </div>
        </div>
    </>)

}

export default HomePageLoggedIn
