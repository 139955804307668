import './ProjectWarnings.css'
import {useTranslation} from "react-i18next";
import React from "react";

/**
 * To show the list of warnings.
 *
 * props.project: The project
 * props.fieldName: The name of the field
 */
function ProjectWarnings(props) {

    const {t} = useTranslation()

    const warnings = props.project.warningsByField || {}
    const warningCodesForField = warnings[props.fieldName] || []

    if (warningCodesForField.length === 0) {
        return <></>
    }

    return <ul>
        {warningCodesForField.map((warningCode) => {
            return <li className="project-warning" key={warningCode}>{t(warningCode)}</li>
        })}
    </ul>

}

export default ProjectWarnings
