import React, {useEffect, useState} from "react";
import './FileUpload.css'
import {useTranslation} from "react-i18next";
import {getAllErrors} from "./Forms";

/**
 * To send one or multiple files.
 * - name : The name for the input (for logger)
 * - accept: (optional) the allowed extensions (e.g: ".doc, .docx, .pdf")
 * - multi : (true to allow multiple files)
 * - uploadFile : A callback with the file to upload function(file):Promise<FormResult>
 */
function FileUpload(props) {

    const {t} = useTranslation()

    const [filesToSend, setFilesToSend] = useState([])
    const [fileCurrentlySending, setFileCurrentlySending] = useState(null)
    const [totalSuccess, setTotalSuccess] = useState(0)
    const [failures, setFailures] = useState([])

    const [progress, setProgress] = useState({
        successCount: 0,
        failuresCount: 0,
        waitingCount: 0,
        totalCount: 0,

        successPercent: 0,
        waitingPercent: 0,
        failurePercent: 0,
    })

    // Update progress
    useEffect(() => {
            const successCount = totalSuccess
            const failuresCount = failures.length
            const waitingCount = filesToSend.length + (fileCurrentlySending === null ? 0 : 1)
            const totalCount = successCount + failuresCount + waitingCount

            let successPercent = totalCount !== 0 ? Math.floor(successCount / totalCount * 100) : 0
            let waitingPercent = totalCount !== 0 ? Math.floor(waitingCount / totalCount * 100) : 0
            let failurePercent = totalCount !== 0 ? Math.floor(failuresCount / totalCount * 100) : 0

            let extraPercent = 100 - successPercent - waitingPercent - failurePercent
            if (extraPercent > 0) {
                if (successPercent > 0) {
                    successPercent += extraPercent
                } else if (waitingPercent > 0) {
                    waitingPercent += extraPercent
                } else if (failurePercent > 0) {
                    failurePercent += extraPercent
                }
            }

            const newProgress = {
                successCount: successCount,
                failuresCount: failuresCount,
                waitingCount: waitingCount,
                totalCount: totalCount,

                successPercent: successPercent,
                waitingPercent: waitingPercent,
                failurePercent: failurePercent,
            }
            console.log(`[${props.name}] Progress`, newProgress)
            setProgress(newProgress)
        },
        [totalSuccess, failures, filesToSend, fileCurrentlySending])

    // Upload when needed
    useEffect(() => {
            uploadNext()
        },
        [filesToSend, fileCurrentlySending])

    function onDragOver(event) {
        event.stopPropagation()
        event.preventDefault()

        event.dataTransfer.dropEffect = 'copy'
    }

    function popupSelection() {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = props.accept
        input.multiple = props.multi
        input.onchange = function (e) {
            console.log(`[${props.name}] Got a files selection event`)
            addFiles(e.target.files)
        }
        input.click()
    }

    function onDrop(event) {
        event.stopPropagation()
        event.preventDefault()

        console.log(`[${props.name}] Got a drop event`)
        addFiles(event.dataTransfer.files)
    }

    function addFiles(newFiles) {
        console.log(`[${props.name}] Add files`, newFiles)

        let nextFiles = Array.from(newFiles)
        let nextFailures = [...failures]

        // Verify extension
        if (props.accept) {
            const extensions = props.accept.split(", ")
            const toFails = nextFiles.filter(f => {
                const fileExt = f.name.slice(f.name.lastIndexOf('.')).toLowerCase()
                return !extensions.includes(fileExt)
            })
            toFails.forEach(toFail => nextFailures.push({
                file: toFail,
                error: t('fileUpload.acceptedExtensions') + ': ' + props.accept
            }))
            nextFiles = nextFiles.filter(f => {
                const fileExt = f.name.slice(f.name.lastIndexOf('.')).toLowerCase()
                return extensions.includes(fileExt)
            })
        }

        // Verify multi
        if (!props.multi) {
            if (fileCurrentlySending !== null || totalSuccess > 0) {
                // Pick none
                nextFiles.forEach(file => {
                    nextFailures.push({
                        file: file,
                        error: t('fileUpload.maxOne')
                    })
                })
                nextFiles.length = 0
            } else if (newFiles.length > 1) {
                // Pick only first
                const filesToRemove = []
                while (nextFiles.length > 1) {
                    filesToRemove.push(nextFiles.pop())
                }
                filesToRemove.forEach(file => {
                    nextFailures.push({
                        file: file,
                        error: t('fileUpload.maxOne')
                    })
                })
            }
        }

        // Queue the files
        setFailures(nextFailures)
        setFilesToSend(prevState => [...prevState, ...nextFiles])
    }

    function uploadNext() {

        // Already one in progress
        if (fileCurrentlySending !== null) {
            return
        }

        // Nothing to send
        if (filesToSend.length === 0) {
            return
        }

        // Pick next
        const nextFilesToSend = filesToSend
        let fileToSend = nextFilesToSend.shift()
        setFilesToSend(nextFilesToSend)
        setFileCurrentlySending(fileToSend)

        console.log(`[${props.name}] Uploading file`, fileToSend)
        props.uploadFile(fileToSend)
            .then((response) => {
                console.log(`[${props.name}] Uploading file SUCCESS`, response)
                setTotalSuccess(prev => prev + 1)
                setFileCurrentlySending(null)
            }, (response) => {
                console.log(`[${props.name}] Uploading file FAILURE`, response)
                setFailures(prev => [...prev, {
                    file: fileToSend,
                    error: getAllErrors(response).join(' / '),
                }])
                setFileCurrentlySending(null)
            })
    }

    return (<div
            className="dropZone"
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            <div className="text-center" onClick={e => popupSelection()}>
                <p>{t('fileUpload.dropZone')}</p>
                {props.accept && <p>({t('fileUpload.acceptedExtensions')}: {props.accept})</p>}
            </div>

            {progress.totalCount !== 0 &&
                <div className="progress">
                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar"
                         style={{width: progress.successPercent + "%"}}
                         aria-valuenow={progress.successCount}
                         aria-valuemin={0} aria-valuemax={progress.totalCount}>{progress.successCount}</div>
                    <div className="progress-bar progress-bar-striped bg-info" role="progressbar"
                         style={{width: progress.waitingPercent + "%"}}
                         aria-valuenow={progress.waitingCount}
                         aria-valuemin={0} aria-valuemax={progress.totalCount}>{progress.waitingCount}</div>
                    <div className="progress-bar progress-bar-striped bg-danger" role="progressbar"
                         style={{width: progress.failurePercent + "%"}}
                         aria-valuenow={progress.failuresCount} aria-valuemin={0}
                         aria-valuemax={progress.totalCount}>{progress.failuresCount}</div>
                </div>
            }

            {fileCurrentlySending &&
                <div className="bg-info">
                    {t('fileUpload.current')}: {fileCurrentlySending.name}
                </div>
            }

            {filesToSend.length > 0 &&
                <div className="bg-info">
                    {t('fileUpload.next')}:
                    <ul>
                        {filesToSend
                            .filter((_, i) => i < 5)
                            .map((file, fId) => <li key={fId}>{file?.name}</li>)
                        }
                        {filesToSend.length > 5 && <li>...</li>}
                    </ul>
                </div>
            }

            {failures.length > 0 &&
                <div className="bg-danger">
                    {t('fileUpload.failures')}:
                    <ul>
                        {failures.map((failure, fId) => <li key={fId}>{failure.file?.name} : {failure.error}</li>)}
                    </ul>
                </div>
            }

        </div>
    )


}

export default FileUpload
