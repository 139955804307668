/**
 * Show any global errors and warnings.
 *
 * props.state: The current state
 * props.onPageChange(newPageId): The action to do when changing the page
 */
function PaginationControl(props) {
    const state = props.state ? props.state : {};
    return (
        <div className={props.className}>
            <button className="btn btn-small btn-primary" disabled={state.firstPage}
                    onClick={() => props.onPageChange(state.currentPageUi - 1)}>&lt;</button>
            {state.currentPageUi} / {state.totalPages}
            <button className="btn btn-small btn-primary" disabled={state.lastPage}
                    onClick={() => props.onPageChange(state.currentPageUi + 1)}>&gt;</button>
        </div>
    )
}

export default PaginationControl;
