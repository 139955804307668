import React, {useState} from "react";
import {updateFormValue} from "../common/Forms";
import {autoRetry} from "../service";
import {toast} from "react-toastify";
import FieldError from "../common/FieldError";
import GlobalErrorsAndWarnings from "../common/GlobalErrorsAndWarnings";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

/**
 * Change the password of a user with a token.
 */
function UserChangePasswordPage() {

    const {t} = useTranslation()

    // Get the token parameter
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token')

    const [form, setForm] = useState({
        token: token,
        password: '',
        passwordConfirmation: '',
    })
    const [formResult, setFormResult] = useState()

    function changePassword() {
        setFormResult(null)
        autoRetry(t('login.changePasswordUser.context'), () => window.service.userChangePassword(form), 5).then(response => {
            setFormResult(response.data)
            if (response.data.success) {
                toast.success(t('login.changePasswordUser.success'))

                // Redirect to login page
                window.location.href = "/#/login"
            }
        })
    }

    // If no token provided, display an error
    if (!token) {
        return (<>
            <div className="row">
                <div className="col col-sm-6 col-md-4 col-lg-4">
                    <h2>{t('login.changePasswordUser.title')}</h2>
                    <div className="alert alert-danger">{t('login.changePasswordUser.noToken')}</div>
                </div>
            </div>
        </>)
    }

    return (
        <div className="row">
            <div className="col col-sm-6 col-md-4 col-lg-4">
                <h2>{t('login.changePasswordUser.title')}</h2>

                <GlobalErrorsAndWarnings formResult={formResult}/>

                <FieldError formResult={formResult} fieldName="token"/>

                <input type="password" className="form-control"
                       autoComplete="off"
                       name="password"
                       placeholder={t('login.password')}
                       value={form.password}
                       onChange={(e) => updateFormValue(e, form, setForm)}
                />
                <FieldError formResult={formResult} fieldName="password"/>

                <input type="password" className="form-control"
                       autoComplete="off"
                       name="passwordConfirmation"
                       placeholder={t('login.passwordConfirmation')}
                       value={form.passwordConfirmation}
                       onChange={(e) => updateFormValue(e, form, setForm)}
                />
                <FieldError formResult={formResult} fieldName="passwordConfirmation"/>

                <button className="btn btn-success float-end"
                        onClick={() => changePassword()}
                >{t('login.changePasswordUser.button')}
                </button>
            </div>
        </div>
    )
}

export default UserChangePasswordPage
