import React, {useState} from "react";
import {updateFormValue} from "../common/Forms";
import {autoRetry} from "../service";
import {toast} from "react-toastify";
import FieldError from "../common/FieldError";
import GlobalErrorsAndWarnings from "../common/GlobalErrorsAndWarnings";
import {useTranslation} from "react-i18next";

/**
 * Create a token for a user
 *
 * props.action: The action to perform (createUser or forgotPasswordUser)
 */
function UserCreateTokenPage(props) {

    const {t} = useTranslation()

    const [form, setForm] = useState({
        username: '',
    })
    const [formResult, setFormResult] = useState()

    function createToken() {
        setFormResult(null)
        autoRetry(t('login.' + props.action + '.context'), () => window.service.userCreateToken(form), 5).then(response => {
            setFormResult(response.data)
            if (response.data.success) {
                toast.success(t('login.' + props.action + '.success'))
            }
        })
    }

    return (<>
        <div className="row">
            <div className="col col-sm-6 col-md-4 col-lg-4">
                <h2>{t('login.' + props.action + '.title')}</h2>

                <GlobalErrorsAndWarnings formResult={formResult} />

                <input type="text" className="form-control"
                       autoComplete="off"
                       name="username"
                       placeholder={t('login.username')}
                       value={form.username}
                       onChange={(e) => updateFormValue(e, form, setForm)}
                />
                <FieldError formResult={formResult} fieldName="username"/>

                <button className="btn btn-success float-end"
                        onClick={() => createToken()}
                >{t('login.' + props.action + '.button')}
                </button>
            </div>
        </div>

    </>)
}

export default UserCreateTokenPage
