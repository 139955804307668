import React, {useEffect, useState} from "react";
import {autoRetry} from "../service";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PaginationControl from "../common/PaginationControl";
import {useLogin} from "../common/CustomHooks";
import {toIsoFullDate} from "../common/DateUtils";

/**
 * The project listing page.
 *
 * props.userEntitlements: The users entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function ProjectListPage(props) {

    const {t} = useTranslation()

    // Initial details
    const [listResponse, setListResponse] = useState([])
    const [pageId, setPageId] = useState(1)

    useLogin(props.userEntitlements, props.needsLogin, '/project')

    useEffect(() => {
        if (props.userEntitlements && props.userEntitlements.isLoggedIn) {
            refreshPage(pageId)
        }
    }, [props.userEntitlements, pageId])

    if (!props.userEntitlements) {
        return <></>
    }

    function refreshPage(pageId) {
        setPageId(pageId)
        autoRetry(t('project.context'), () => window.service.projectFindAll(pageId), 5).then(response => {
            setListResponse(response.data)
        })
    }

    return (
        <div className="row">
            <div className="col">

                <h2>{t('project.title-plural')}</h2>

                {props.userEntitlements.entitlements.canCreateProject &&
                    <NavLink to="/project-new" className="btn btn-success float-end">{t('common.create')}</NavLink>}

                <div className="clearfix"></div>

                <br/>

                <PaginationControl
                    className="float-end"
                    state={listResponse.pagination}
                    onPageChange={newPageId => setPageId(newPageId)}
                />

                <div className="clearfix"></div>

                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>{t('project.buildingAddress')}</th>
                            <th>{t('project.creationDate')}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {listResponse.items && listResponse.items.map(project => (
                            <tr key={project.id}>
                                <td>
                                    {project.buildingAddress?.full === undefined &&
                                        <NavLink to={`/project/${project.id}`}>{project.id}</NavLink>
                                        ||
                                        <NavLink to={`/project/${project.id}`}>{project.buildingAddress.full}</NavLink>
                                    }
                                </td>
                                <td>{toIsoFullDate(project.creationDate)}</td>
                                <td></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <PaginationControl
                    className="float-end"
                    state={listResponse.pagination}
                    onPageChange={newPageId => setPageId(newPageId)}
                />

            </div>
        </div>
    )
}

export default ProjectListPage
