import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {autoRetry} from "../service";
import {toast} from "react-toastify";
import FieldError from "../common/FieldError";

/**
 * The billing creation dialog.
 *
 * props.initial : The initial data
 * props.onClose : A callback function(shouldRefresh) to close the dialog
 */
function BillCreateDialog(props) {

    const tpsPercent = 0.05;
    const tvqPercent = 0.09975;

    const {t} = useTranslation()

    const [formResult, setFormResult] = useState({})
    const [form, setForm] = useState({
        business: props.initial.business,
        period: props.initial.period,
        businessId: props.initial.business.id,
        units: props.initial.units,
        pricePerUnitInCents: 0,
        subtotalInCents: 0,
        tpsInCents: 0,
        tvqInCents: 0,
        totalInCents: 0,
    })

    const [pricePerUnit, setPricePerUnit] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)

    // Initial calculation
    useEffect(() => {
        const initialForm = {
            business: props.initial.business,
            period: props.initial.period,
            businessId: props.initial.business.id,
            units: props.initial.units,
            pricePerUnitInCents: 1000,
        }
        initialForm.subtotalInCents = initialForm.units * initialForm.pricePerUnitInCents
        initialForm.tpsInCents = Math.round(initialForm.subtotalInCents * tpsPercent)
        initialForm.tvqInCents = Math.round(initialForm.subtotalInCents * tvqPercent)
        initialForm.totalInCents = initialForm.subtotalInCents + initialForm.tpsInCents + initialForm.tvqInCents

        setForm(initialForm)
        setPricePerUnit((initialForm.pricePerUnitInCents / 100).toFixed(2))
        setSubTotal((initialForm.subtotalInCents / 100).toFixed(2))
        setTotal((initialForm.totalInCents / 100).toFixed(2))
    }, [])


    function changeUnits(e) {
        const newUnits = parseFloat(e.target.value)
        const newForm = {...form, units: newUnits}
        newForm.subtotalInCents = newForm.units * newForm.pricePerUnitInCents
        newForm.tpsInCents = Math.round(newForm.subtotalInCents * tpsPercent)
        newForm.tvqInCents = Math.round(newForm.subtotalInCents * tvqPercent)
        newForm.totalInCents = newForm.subtotalInCents + newForm.tpsInCents + newForm.tvqInCents
        setForm(newForm)
        setPricePerUnit((newForm.pricePerUnitInCents / 100).toFixed(2))
        setSubTotal((newForm.subtotalInCents / 100).toFixed(2))
        setTotal((newForm.totalInCents / 100).toFixed(2))
    }

    function changePricePerUnit(e) {
        const pricePerUnitInCents = Math.floor(parseFloat(e.target.value) * 100)
        const newForm = {...form, pricePerUnitInCents: pricePerUnitInCents}
        newForm.subtotalInCents = newForm.units * newForm.pricePerUnitInCents
        newForm.tpsInCents = Math.round(newForm.subtotalInCents * tpsPercent)
        newForm.tvqInCents = Math.round(newForm.subtotalInCents * tvqPercent)
        newForm.totalInCents = newForm.subtotalInCents + newForm.tpsInCents + newForm.tvqInCents
        setForm(newForm)
        setPricePerUnit(e.target.value)
        setSubTotal((newForm.subtotalInCents / 100).toFixed(2))
        setTotal((newForm.totalInCents / 100).toFixed(2))
    }

    function changeSubTotal(e) {
        const subTotalInCents = Math.floor(parseFloat(e.target.value) * 100)
        const newForm = {...form, subtotalInCents: subTotalInCents}
        newForm.pricePerUnitInCents = Math.round(newForm.subtotalInCents / newForm.units)
        newForm.tpsInCents = Math.round(newForm.subtotalInCents * tpsPercent)
        newForm.tvqInCents = Math.round(newForm.subtotalInCents * tvqPercent)
        newForm.totalInCents = newForm.subtotalInCents + newForm.tpsInCents + newForm.tvqInCents
        setPricePerUnit((newForm.pricePerUnitInCents / 100).toFixed(2))
        setSubTotal(e.target.value)
        setTotal((newForm.totalInCents / 100).toFixed(2))
        setForm(newForm)
    }

    function changeTotal(e) {
        const totalInCents = Math.floor(parseFloat(e.target.value) * 100)
        const newForm = {...form, totalInCents: totalInCents}
        newForm.subtotalInCents = newForm.totalInCents / (1 + tpsPercent + tvqPercent)
        newForm.tpsInCents = Math.round(newForm.subtotalInCents * tpsPercent)
        newForm.tvqInCents = Math.round(newForm.subtotalInCents * tvqPercent)
        newForm.pricePerUnitInCents = Math.round(newForm.subtotalInCents / newForm.units)
        setPricePerUnit((newForm.pricePerUnitInCents / 100).toFixed(2))
        setSubTotal((newForm.subtotalInCents / 100).toFixed(2))
        setTotal(e.target.value)
        setForm(newForm)
    }

    function create() {
        setFormResult(null)
        autoRetry(t('common.create'), () => window.service.billCreate(form), 5).then(response => {
            setFormResult(response.data)
            if (response.data.success) {
                toast.success(t('common.createSuccess'))
                props.onClose(true)
            }
        })
    }

    return (
        <div className="modal fade show d-block" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('bill.title')}</h5>
                        <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => props.onClose(false)}
                        ></button>
                    </div>
                    <div className="modal-body">

                        <table className="table">
                            <tbody>
                            <tr>
                                <th>{t('bill.period')}</th>
                                <td>
                                    <input type="text" className="form-control"
                                           autoComplete="off"
                                           name="period"
                                           value={form.period}
                                           disabled={true}
                                    />
                                    <FieldError formResult={formResult} fieldName="period"/>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('bill.business')}</th>
                                <td>
                                    <input type="text" className="form-control"
                                           autoComplete="off"
                                           name="business"
                                           value={form.business.name}
                                           disabled={true}
                                    />
                                    <FieldError formResult={formResult} fieldName="businessId"/>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('bill.units')}</th>
                                <td>
                                    <input type="number" className="form-control"
                                           autoComplete="off"
                                           name="units"
                                           value={form.units}
                                           onChange={changeUnits}
                                    />
                                    <FieldError formResult={formResult} fieldName="units"/>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('bill.pricePerUnit')}</th>
                                <td>
                                    <input type="text" className="form-control"
                                           autoComplete="off"
                                           name="pricePerUnit"
                                           value={pricePerUnit}
                                           onChange={changePricePerUnit}
                                    />
                                    <FieldError formResult={formResult} fieldName="pricePerUnitInCents"/>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('bill.subtotal')}</th>
                                <td>
                                    <input type="text" className="form-control"
                                           autoComplete="off"
                                           name="subtotal"
                                           value={subTotal}
                                           onChange={changeSubTotal}
                                    />
                                    <FieldError formResult={formResult} fieldName="subtotalInCents"/>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('bill.tps')}</th>
                                <td>
                                    <input type="text" className="form-control"
                                           autoComplete="off"
                                           name="tps"
                                           value={(form.tpsInCents / 100).toFixed(2)}
                                           disabled={true}
                                    />
                                    <FieldError formResult={formResult} fieldName="tpsInCents"/>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('bill.tvq')}</th>
                                <td>
                                    <input type="text" className="form-control"
                                           autoComplete="off"
                                           name="tvq"
                                           value={(form.tvqInCents / 100).toFixed(2)}
                                           disabled={true}
                                    />
                                    <FieldError formResult={formResult} fieldName="tvqInCents"/>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('bill.total')}</th>
                                <td>
                                    <input type="text" className="form-control"
                                           autoComplete="off"
                                           name="total"
                                           value={total}
                                           onChange={changeTotal}
                                    />
                                    <FieldError formResult={formResult} fieldName="totalInCents"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => props.onClose(false)}>{t('common.close')}</button>
                        <button type="button" className="btn btn-primary" onClick={create}>{t('common.create')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillCreateDialog
