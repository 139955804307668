import {useTranslation} from "react-i18next";

/**
 * Checkedbox.
 * - label : The label for the input
 * - name : The name for the input
 * - value : The current value
 * - onChangeValue : A callback function(newValue)
 */
function CheckboxBoolean(props) {

    const {t, i18n} = useTranslation()

    const value = props.value ? props.value : false

    return (
        <div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox"
                       name={props.name}
                       checked={props.value}
                       onChange={() => props.onChangeValue(!props.value)}
                />
                <label className="form-check-label" htmlFor={props.name}>
                    {props.label}
                </label>
            </div>
        </div>
    )

}

export default CheckboxBoolean
